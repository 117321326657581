export function getWindowInnerWidth() {
  if (window.visualViewport) {
    return window.visualViewport.width;
  } else {
    return window.innerWidth;
  }
}

export function getWindowInnerHeight() {
  if (window.visualViewport) {
    return window.visualViewport.height;
  } else {
    return window.innerHeight;
  }
}

export function getWindowScrollY() {
  if (window.scrollY) {
    return window.scrollY;
  } else {
    return window.pageYOffset;
  }
}

export function sendNaverWcsLog() {
  if (!window.wcs_add) {
    window.wcs_add={};
  }
  window.wcs_add["wa"] = "s_133faf72a219";
  window._nasa={};
  if (window.wcs) {
    window._nasa["cnv"] = window.wcs.cnv("4","1");
    window.wcs_do(window._nasa);
  }
}
