export const Tag = ({style, title}) => {
  return (
    <div style={{
      ...style,
      height: 14,
      color: 'white',
      fontSize: 8,
      fontWeight: 700,
      background: '#FF5A00',
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline-flex',
      paddingLeft: 6,
      paddingRight: 6,
      pointerEvents: 'none',
    }}>
      {title}
    </div>
  );
}
