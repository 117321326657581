import {Grid, Image} from "semantic-ui-react";
import React from "react";
import "./ServiceCustomer.css"
import ServiceCustomerLogo from "../assets/serviceCustomerLogo.svg";

import logo1 from "../assets/company/gsRetail.svg";
import logo2 from "../assets/company/gsConstruction.svg";
import logo3 from "../assets/company/gsE&R.svg";
import logo4 from "../assets/company/gsCaltex.svg";
import logo5 from "../assets/company/gsNetworks.svg";
import logo6 from "../assets/company/gsEntech.svg";
import logo7 from "../assets/company/hyundaimotors.svg";
import logo9 from "../assets/company/kyoboensurance.svg";
import logo10 from "../assets/company/ourhome.svg";


import logo11 from "../assets/company/hugel.svg";
import logo12 from "../assets/company/ktSkylife.svg";
import logo13 from "../assets/company/samsungkijeon.svg";
import logo14 from "../assets/company/baemin.svg";
import logo15 from "../assets/company/nousnet.svg";

import logo16 from "../assets/company/getandshow.svg";
import logo17 from "../assets/company/golfanzi.svg";
import logo18 from "../assets/company/greenchemical.svg";
import logo19 from "../assets/company/vivozon.svg";
import logo20 from "../assets/company/wycom.svg";

import logo21 from "../assets/company/apsystem.svg";
import logo22 from "../assets/company/vitzromiltech.svg";
import logo23 from "../assets/company/wooribank.svg";
import logo24 from "../assets/company/cjenm.svg";
import logo25 from "../assets/company/lottedrink.svg";
import logo8 from "../assets/company/kbEnsuarance.svg";


const ServiceCustomer = () => (<div className="customer">
        <div style={{
            position: "relative", margin: "52px auto", width: "520px", height: "50px",
        }}>
            <Image src={ServiceCustomerLogo} style={{position: "absolute"}} width="100%"/>
        </div>
        <Grid relaxed='very'
              centered
              columns={5}
              style={{
                  width: "1440px", marginLeft: "165px", marginRight: "165px", marginBottom: "120px"
              }}
        >
            <Grid.Row columns={5} verticalAlign="middle">
                <Grid.Column width={3}>
                    <Image src={logo1} width='195px' height='94px'/>
                </Grid.Column>

                <Grid.Column width={3}>

                    <Image src={logo2} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo3} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo4} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo5} width='195px' height='94px'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5} verticalAlign="middle">
                <Grid.Column width={3}>

                    <Image src={logo6} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo7} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo8} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo9} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo10} width='195px' height='94px'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5} verticalAlign="middle">
                <Grid.Column width={3}>

                    <Image src={logo11} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo12} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo13} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo14} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo15} width='195px' height='94px'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5} verticalAlign="middle">
                <Grid.Column width={3}>

                    <Image src={logo16} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo17} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo18} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo19} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo20} width='195px' height='94px'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5} verticalAlign="middle">
                <Grid.Column width={3}>

                    <Image src={logo21} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo22} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo23} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo24} width='195px' height='94px'/>
                </Grid.Column>
                <Grid.Column width={3}>

                    <Image src={logo25} width='195px' height='94px'/>
                </Grid.Column>
            </Grid.Row>

        </Grid>

    </div>

);

export default ServiceCustomer;
