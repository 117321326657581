import React from "react";
import {Image, Modal} from "semantic-ui-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const MenuGalleryMo = ({isMobile}) => {
    const galleries = [
        {id: 1, filePath: "./images/gallery/gallery_01.jpg", menuName: "샌드위치"},
        {id: 2, filePath: "./images/gallery/gallery_02.jpg", menuName: "샐러드"},
        {id: 3, filePath: "./images/gallery/gallery_03.jpg", menuName: "김밥"},
        {id: 4, filePath: "./images/gallery/gallery_09.jpg", menuName: "주먹밥"},
        {id: 5, filePath: "./images/gallery/gallery_08.jpg", menuName: "도시락"},
        {id: 6, filePath: "./images/gallery/gallery_07.jpg", menuName: "햄버거"},
        {id: 7, filePath: "./images/gallery/gallery_04.jpg", menuName: "컵과일"},
        {id: 8, filePath: "./images/gallery/gallery_05.jpg", menuName: "디저트"},
        {id: 9, filePath: "./images/gallery/gallery_06.jpg", menuName: "음료"},
    ];

    const [pageNumber, setPageNumber] = React.useState({
        currNum: 1,
        totalPage: galleries.length,
    });

    const [open, setOpen] = React.useState(false);
    const [imgModalOpen, setImgModalOpen] = React.useState(false);
    const [zoomImgPath, setZoomImgPath] = React.useState('');
    const sliderRef = React.useRef();

    const modalStyle2 = {
        width: "100%",//windowSize.width + "px",
        height: "100vh",//windowSize.height + "px",
        margin: "0px",
        padding: "0px",
        top: "0px",
        left: "0px",
        backgroundColor: "black"
    }
    const imgLabelDivStyle = {
        height: "300px"
    }

    const headerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        height: "48px",
        border: "0px",
        padding: "0px",
        paddingTop: "12px",
        paddingLeft: "24px",
        paddingRight: "18px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "#1E2530",
    }
    const modalCloseIconStyle = {
        padding: "6px",
        display: "float",
        width: "24px",
        height: "24px",
        cursor: "pointer",
    }
    const contentStyle = {
        position: "relative",
        margin: "0px",
        padding: "0px",
        height: "334px",

    }
    const sliderStyle = {
        position: "absolute",
        padding: "0px",
        width: "100%",
        left: "50%",
        top: "46%",
        transform: "translate(-50%, -50%)",
    }
    const leftStyle = {
        position: "absolute",
        top: "113px",
        left: "8px",
        zIndex: "10",
        cursor: "pointer",
        border: "1px solid #000000",
        borderRadius: "4px"
    }
    const rightStyle = {
        position: "absolute",
        top: "113px",
        left: "288px",
        zIndex: "10",
        cursor: "pointer",
        border: "1px solid #000000",
        borderRadius: "4px"
    }
    const cursorSizeStyle = {
        width: "24px",
        height: "24px"
    }

    const galleryStyle = {
        height: "700px",
        position: "absolute",
        top: "0px",
        left: "0px",
        justifyContent: "center",
        width: "100%",
        display: "flex",
        alignItems: "center",
    }
    const galleryNameStyle = {
        position: "absolute",
        top: "262px",
        justifyContent: "center",
        width: "320px",
        height: "20px",
        zIndex: "11",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "30px",

        display: "flex",
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "-0.4px",
        color: "#000000",
    }
    if (!isMobile) {
        galleryNameStyle.display = "flex";
        galleryNameStyle.alignItems = "center";
    }
    const pageNumStyle = {
        position: "absolute",
        left: "145px",
        top: "290px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "28px",
        letterSpacing: "-0.4px",
    }

    const currpageStyle = {
        color: "#000000",
    }
    const totalPageStyle = {
        color: "rgba(0, 0, 0, 0.4)",
    }

    const onLeftClick = () => {
        sliderRef.current.slickPrev();
    }

    const onRightClick = () => {
        sliderRef.current.slickNext();
    }

    // React.useEffect(() => {
    //     function handleTouchMove(event) {
    //         if (open) {
    //             event.preventDefault(); // 여기가 핵심
    //         }
    //     }
    //     window.addEventListener("touchmove", handleTouchMove, {
    //         passive: false
    //     });
    //     return () =>
    //         window.removeEventListener("touchmove", handleTouchMove);
    // }, [open]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                setOpen(true);
                setPageNumber({
                    ...pageNumber,
                    currNum: 1,
                });
            }}
            open={open}
            // style={modalStyle}
            style={{
                width: "320px",
                height: "334px",
                top: "39px"

            }}
            trigger={<button className="btn-more-mo">카테고리별 메뉴 보기</button>}
        >
            <div style={headerStyle}>
                메뉴 갤러리
                <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
                       style={modalCloseIconStyle}/>
            </div>
            <Modal.Content style={contentStyle}>
                <div style={leftStyle} onClick={onLeftClick}>
                    <Image src="./images/icon/icon_gallery_left.svg" style={cursorSizeStyle}></Image>
                </div>
                <div style={sliderStyle}>
                    <Slider id="testSlider" ref={sliderRef} arrows={false}
                            beforeChange={
                                (current, next) => {
                                    setPageNumber({
                                        ...pageNumber,
                                        currNum: next + 1,
                                    });
                                }}>
                        {

                            galleries.map((image) => (
                                <div key={image.id} style={galleryStyle}>
                                    <div onClick={() => {
                                        setImgModalOpen(true);
                                        setZoomImgPath(image.filePath);
                                    }}
                                         style={imgLabelDivStyle}>
                                        <Image src={image.filePath}/>
                                        <div style={galleryNameStyle}>
                                            <label>{image.menuName}</label>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }
                    </Slider>
                </div>
                <div style={rightStyle} onClick={onRightClick}>
                    <Image src="./images/icon/icon_gallery_right.svg" style={cursorSizeStyle}></Image>
                </div>
                <div style={pageNumStyle}>
                    <label style={currpageStyle}>{pageNumber.currNum}</label>
                    <label style={totalPageStyle}> / {pageNumber.totalPage}</label>
                </div>
            </Modal.Content>
            <Modal
                onClose={() => setImgModalOpen(false)}
                open={imgModalOpen}
                style={modalStyle2}
            >
                <Modal.Header style={{backgroundColor: "black", textAlign: "right"}}>
                    <div style={{
                            position: "absolute",
                            left: "326px",
                            top: "18px"
                        }}
                    >
                        <Image src='./images/icon/icon_close_white.svg'
                               onClick={() => setImgModalOpen(false)}
                               style={modalCloseIconStyle}/>
                    </div>
                </Modal.Header>
                <Modal.Content style={{backgroundColor: "black"}}>
                    <div style={{height: "100%", marginTop: "140px"}}>
                        <Image src={zoomImgPath}></Image>
                    </div>
                </Modal.Content>
            </Modal>


        </Modal>
    );
};

export default MenuGalleryMo;
