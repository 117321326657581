import React from "react";
import {Image, Modal} from "semantic-ui-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const MenuGallery = ({isPc}) => {
    const galleries = [
        {id: 1, filePath: "./images/gallery/gallery_01.jpg", menuName: "샌드위치"},
        {id: 2, filePath: "./images/gallery/gallery_02.jpg", menuName: "샐러드"},
        {id: 3, filePath: "./images/gallery/gallery_03.jpg", menuName: "김밥"},
        {id: 4, filePath: "./images/gallery/gallery_09.jpg", menuName: "주먹밥"},
        {id: 5, filePath: "./images/gallery/gallery_08.jpg", menuName: "도시락"},
        {id: 6, filePath: "./images/gallery/gallery_07.jpg", menuName: "햄버거"},
        {id: 7, filePath: "./images/gallery/gallery_04.jpg", menuName: "컵과일"},
        {id: 8, filePath: "./images/gallery/gallery_05.jpg", menuName: "디저트"},
        {id: 9, filePath: "./images/gallery/gallery_06.jpg", menuName: "음료"},
    ];

    const [pageNumber, setPageNumber] = React.useState({
        currNum: 1,
        totalPage: galleries.length,
    });

    const [open, setOpen] = React.useState(false);
    const sliderRef = React.useRef();

    const modalStyle = {
        width: (isPc ? "830px" : "320px"),
        top: isPc ? "28px" : "28px",
        height: (isPc ? "764px" : "334px"),
        padding: "0px",
    }
    const imgLabelDivStyle = {
        height: (isPc ? "700px" : "300px")
    }

    const headerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        height: "48px",
        border: "0px",
        padding: "0px",
        paddingTop: "12px",
        paddingLeft: "24px",
        paddingRight: "18px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "#1E2530",
    }
    const modalCloseIconStyle = {
        padding: "6px",
        display: "float",
        width: "24px",
        height: "24px",
        cursor: "pointer",
    }
    const contentStyle = {
        position: "relative",
        margin: "0px",
        padding: "0px",
        height: (isPc ? "604px" : "334px"),
    }
    const sliderStyle = {
        position: "absolute",
        padding: "0px",
        width: "100%",
        left: "50%",
        top: isPc ? "0px" : "46%",
        transform: isPc ? "translate(-50%, 0%)" : "translate(-50%, -50%)",
    }
    const leftStyle = {
        position: "absolute",
        top: isPc ? "268px" : "113px",
        left: (isPc ? "9px" : "0px"),
        zIndex: "10",
        cursor: "pointer",
    }
    if (!isPc) {
        leftStyle.width = "40px";
        leftStyle.height = "40px";
        leftStyle.padding = "8px";
    }
    const rightStyle = {
        position: "absolute",
        top: isPc ? "268px" : "113px",
        left: isPc ? "762px" : "280px",
        zIndex: "10",
        cursor: "pointer",
    }
    if (!isPc) {
        rightStyle.width = "40px";
        rightStyle.height = "40px";
        rightStyle.padding = "8px";
    }
    const galleryStyle = {
        height: "700px",
        position: "absolute",
        top: "0px",
        left: "0px",
        justifyContent: "center",
        width: "100%",
        display: "flex",
        alignItems: "center",
    }
    const galleryNameStyle = {
        position: "absolute",
        top: isPc ? "630px" : "262px",
        justifyContent: "center",
        width: isPc ? "820px" : "320px",
        height: isPc ? "30px" : "20px",
        zIndex: "11",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: isPc ? "20px" : "14px",
        lineHeight: "30px",

        display: "flex",
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "-0.4px",
        color: "#000000",
    }
    if (!isPc) {
        galleryNameStyle.display = "flex";
        galleryNameStyle.alignItems = "center";
    }
    const pageNumStyle = {
        position: "absolute",
        left: isPc ? "390px" : "145px",
        top: isPc ? "670px" : "290px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: isPc ? "20px" : "16px",
        lineHeight: "28px",
        letterSpacing: "-0.4px",
    }

    const currpageStyle = {
        color: "#000000",
    }
    const totalPageStyle = {
        color: "rgba(0, 0, 0, 0.4)",
    }

    const onLeftClick = () => {
        sliderRef.current.slickPrev();
    }

    const onRightClick = () => {
        sliderRef.current.slickNext();
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                setOpen(true);
                setPageNumber({
                    ...pageNumber,
                    currNum: 1,
                });
            }}
            open={open}
            style={modalStyle}
            trigger={<button className="btn-more">카테고리별 메뉴 보기</button>}
        >
            <div style={headerStyle}>
                메뉴 갤러리
                <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
                       style={modalCloseIconStyle}/>
            </div>
            <Modal.Content style={contentStyle}>
                <div style={leftStyle} onClick={onLeftClick}>
                    <Image src="./images/icon/icon_gallery_left.svg"></Image>
                </div>
                <div style={sliderStyle}>
                    <Slider id="testSlider" ref={sliderRef} arrows={false}
                            beforeChange={
                                (current, next) => {
                                    setPageNumber({
                                        ...pageNumber,
                                        currNum: next+1,
                                    });
                                }}>
                        {

                            galleries.map((image) => (
                                <div key={image.id} style={galleryStyle}>
                                    <div style={imgLabelDivStyle}>
                                        <Image src={image.filePath}/>
                                        <div style={galleryNameStyle}>
                                            <label>{image.menuName}</label>
                                        </div>
                                    </div>
                                </div>

                            ))}
                    </Slider>
                </div>
                <div style={rightStyle} onClick={onRightClick}>
                    <Image src="./images/icon/icon_gallery_right.svg"></Image>
                </div>
                <div style={pageNumStyle}>
                    <label style={currpageStyle}>{pageNumber.currNum}</label>
                    <label style={totalPageStyle}> / {pageNumber.totalPage}</label>
                </div>
            </Modal.Content>

        </Modal>
    );
};

export default MenuGallery;
