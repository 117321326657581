import {Embed, Image} from "semantic-ui-react";
import "./ServiceFeature.css"

export const ServiceFeature = () => {
    return (
        <div className="feature">
            <div className="youtube">
                <Embed style={{width: "100%", height: "100%"}}
                       id='JsaDIZQmIro'
                       placeholder='./images/promotion.jpg'
                       source='youtube'
                       active
                />
            </div>
            <div className="cards">
                <div className="core">
                    <Image src="./images/feature_core.png"></Image>
                </div>
                <div className="group">
                    <Image style={{position: "absolute", width: "360px", height: "200px", left: "0px", top: "0px"}}
                           src='./images/feature1.png'/>
                    <Image style={{position: "absolute", width: "360px", height: "200px", left: "380px", top: "0px"}}
                           src='./images/feature2.png'/>
                    <Image style={{position: "absolute", width: "360px", height: "200px", left: "0px", top: "220px"}}
                           src='./images/feature3.png'/>
                    <Image style={{position: "absolute", width: "360px", height: "200px", left: "380px", top: "220px"}}
                           src='./images/feature4.png'/>
                </div>
            </div>
        </div>
    );
}

export const ServiceFeatureMo = () => {
    return (
        <div className="feature-mo">
            <div className="youtube">
                <Embed style={{width: "100%", height: "100%"}}
                       id='JsaDIZQmIro'
                       placeholder='./images/promotion.jpg'
                       source='youtube'
                       active
                />
            </div>
            <div className="cards">
                <div className="card">
                    <Image src='./images/feature1_mo.png'/>
                </div>
                <div className="card">
                    <Image src='./images/feature2_mo.png'/>
                </div>
                <div className="card">
                    <Image src='./images/feature3_mo.png'/>
                </div>
                <div className="card">
                    <Image src='./images/feature4_mo.png'/>
                </div>
                <div className="card">
                    <Image src='./images/feature5_mo.png'/>
                </div>
            </div>
        </div>
    );
}