import {Image} from "semantic-ui-react";
import React from "react";
import "./ServiceBenefit.css";
import MealBox25Logo from '../assets/logo_benefit.svg';
import BenefitImg from '../assets/benefit.png';

export const ServiceBenefit = () => (
    <div className="benefit">
        <div className="frame">
            <div className="left">
                <div className="title">
                    <img src={MealBox25Logo} alt=""/>
                </div>
                <div className="bene-list">
                    <div className="bene-item">
                        <label className="item-title">혜택 1.</label>
                        <label className="item-content">편의점 대비 최대 30% 할인된 가격</label>
                    </div>
                    <div className="bene-item">
                        <label className="item-title">혜택 2.</label>
                        <label className="item-content">장기구독혜택: GS25상품권, 장비무상대여</label>
                    </div>
                    <div className="bene-item">
                        <label className="item-title">혜택 3.</label>
                        <label className="item-content">전국 배송 가능</label>
                    </div>
                </div>
            </div>
            <div className="right">
                <Image src={BenefitImg}></Image>
            </div>
        </div>
    </div>
);

export const ServiceBenefitMo = () => (
    <div className="benefit-mo">
        <div className="title">
            <img src={MealBox25Logo} style={{width: "239px", height: "36px"}} alt=""/>
        </div>
        <div className="bene-list-mo">
            <div className="bene-item-mo">
                <label className="item-title">혜택 1.</label>
                <label className="item-content">편의점 대비 최대 30% 할인된 가격</label>
            </div>
            <div className="bene-item-mo">
                <label className="item-title">혜택 2.</label>
                <label className="item-content">장기구독혜택: GS25상품권, 장비무상대여</label>
            </div>
            <div className="bene-item-mo">
                <label className="item-title">혜택 3.</label>
                <label className="item-content">전국 배송 가능</label>
            </div>
        </div>
        <div className="img-mo">
            <Image src={BenefitImg} style={{width: "312px", height: "188px"}} alt=""></Image>
        </div>
    </div>
);
