import React, {useRef} from "react";
import axios from "axios";
import isEmpty from "lodash.isempty";
import {Button, Card, Checkbox, Grid, GridColumn, GridRow, Image, Input, Modal, Radio, Select} from "semantic-ui-react";
import SearchAddr from "./SearchAddr";
import SearchAddrMo from "./SearchAddrMo";
import "./OnlineRequest.css"
import PersonalInformModal from "./PersonalInformModal";
import PersonalInformModalMo from "./PersonalInformModalMo";
import OnlineRequestLogo from '../assets/onlineRequestLogo.svg';
import OnlineRequestMoLogo from '../assets/onlineRequestMoLogo.svg';
import crypto from 'crypto';
import {sendNaverWcsLog} from "../utils/windowUtil";
import ClipLoader from "react-spinners/ClipLoader";

const PublicKey = {
    key: require('../key/publickey').default, padding: crypto.constants.RSA_PKCS1_PADDING
};

const encryptByPublicKey = (plaintext) => {
    return crypto.publicEncrypt(PublicKey, Buffer.from(plaintext, 'utf8')).toString('base64');
};

export const RequestForm = () => {


    const submitBtnStyle = {
        marginTop: "50px",
        width: "290px",
        height: "64px",
        border: "1px solid #9E9E9E",
        backgroundColor: "#9E9E9E",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontFamily: "Noto Sans KR",
        fontWeight: "bold",
        fontSize: "16px"
    }

    const tableThStyle = {
        fontFamily: "Noto Sans KR", textAlign: "left", // padding: "10px"
    }


    const optionThStyle = {
        width: "140px", // width: "16%",
        fontFamily: "Noto Sans KR", fontWeight: "500", fontSize: "14px", textAlign: "left"
    }
    const textAreaStyle = {
        width: "100%",
        minHeight: '112px',
        resize: "none",
        borderRadius: "8px",
        border: "1px solid #d4d4d5",
        fontFamily: "Noto Sans KR",
        padding: "12px",
        whiteSpace: 'pre-wrap'
    }
    const inputStyle = {
        marginBottom: "24px", height: "48px", width: "100%"
    }
    const inputStyle2 = {
        marginBottom: "24px", height: "48px", width: "100%"
    }
    const gridStyle = {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
    }
    const gridRowStyle = {
        paddingTop: "0px",
        paddingBottom: "0px",
    }


    const [showLoading, setShowLoading] = React.useState(false);

    let [color, setColor] = React.useState("#ffffff");
    const [personalCheck, setPersonalCheck] = React.useState(false);
    const [personalButtonDisableState, setPersonalButtonDisableState] = React.useState(true);

    const [ORG_NM, setORG_NM] = React.useState('');
    const [MANAGER_NM, setMANAGER_NM] = React.useState('');
    const [CONFIRM_MANAGER_NM, setCONFIRM_MANAGER_NM] = React.useState('');
    const [CELL_NO, setCELL_NO] = React.useState('');
    const [EMAIL_ADDR, setEMAIL_ADDR] = React.useState('');
    const [MY_ADDR, setMY_ADDR] = React.useState('');
    const [CONTENT, setCONTENT] = React.useState('');
    const [CONTENT_COUNT, setCONTENT_COUNT] = React.useState(0);
    const [RECOMM_NM, setRECOMM_NM] = React.useState('');
    const [RECOMM_TEL, setRECOMM_TEL] = React.useState('');

    const [UNIT_BUDGET, setUNIT_BUDGET] = React.useState('');
    const [PERSONNEL_TYPE, setPERSONNEL_TYPE] = React.useState('');
    const [RCPT_DAY, setRCPT_DAY] = React.useState('');


    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const [validationCellNoAlert1, setValidationCellNoAlert1] = React.useState(false);
    const [validationCellNoAlert2, setValidationCellNoAlert2] = React.useState(false);
    const [validationRecommNoAlert1, setValidationRecommNoAlert1] = React.useState(false);
    const [validationRecommNoAlert2, setValidationRecommNoAlert2] = React.useState(false);
    const [validationEmailAlert, setValidationEmailAlert] = React.useState(false);
    const [validationOrgNmAlert, setValidationOrgNmAlert] = React.useState(false);
    const [validationManagerNmAlert, setValidationManagerNmAlert] = React.useState(false);
    const [validationContentAlert, setValidationContentAlert] = React.useState(false);


    const [phoneNumberFlag, setPhoneNumberFlag] = React.useState(false);
    const [emailFlag, setEmailFlag] = React.useState(false);

    React.useEffect(() => {
        commonValidationCheck();
    }, [personalCheck, ORG_NM, MANAGER_NM, phoneNumberFlag, emailFlag, MY_ADDR]);

    const orgNmRef = useRef(null);
    const manageNameRef = useRef(null);
    const emailRef = useRef(null);
    const contentRef = useRef(null);

    let rcptTypeStr = "";
    const rcptDayArray = ["월", "화", "수", "목", "금", "토", "일"];
    const [rcptTypeCheckedState, setRcptTypeCheckedState] = React.useState(new Array(7).fill(false));


    const OnlineRequestConfirm = function ({content}) {

        const loadingStyle = {
            display: "block", color: "#000000"
        };


        const confirmStyle = {
            textAlign: "center",
            fontFamily: "Noto Sans KR",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "28px",
            letterSpacing: "-0.4px",
            color: "#000000"
        }
        const confirmHeader = {
            fontFamily: "Noto Sans KR",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000"
        }
        const confirmImgDivStyle = {
            display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "24px"
        }
        const confirmButtonDivStyle = {
            marginTop: "24px", textAlign: "right"
        }
        const confirmButtonStyle = {
            width: "86px",
            height: "40px",
            border: "1px solid rgba(83, 93, 145, 0.12)",
            boxSizing: "border-box",
            borderRadius: "6px",
            background: "white",

            fontFamily: "Noto Sans KR",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000"
        }

        return (<Modal
            style={{width: "656px", height: "387px"}}
            open={confirmOpen}
        >
            <Modal.Content>
                <p style={confirmHeader}>상담접수완료</p>
                <div style={confirmImgDivStyle}>
                    <Image src='./images/icon/icon_confirmCheck.svg'
                           style={{width: "90px", height: "90px"}}
                    />
                </div>
                <div style={confirmStyle}>
                    <p>감사합니다.</p>
                    <p><span style={{color: "#FF5A00"}}>{content}</span>님의 상담 접수 신청이 완료되었습니다.</p>
                    <p>빠른 시간 안에 연락 드리도록 하겠습니다.</p>
                    <p>(영업일 기준 24시간 이내)</p>
                </div>
                <div style={confirmButtonDivStyle}>
                    <Button style={confirmButtonStyle}
                            onClick={() => {
                                window.location.reload();

                            }}>
                        확인
                    </Button>
                </div>

            </Modal.Content>
        </Modal>);
    }
    const checkHeader = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        color: "#000000"
    }
    const checkContent = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "28px",
        color: "#000000"
    }
    const checkBtnDiv = {
        marginTop: "24px", textAlign: "right"
    }
    const checkBtn = {
        width: "86px", height: "40px",

        fontFamily: "Noto Sans KR", fontStyle: "normal", fontWeight: "bold", fontSize: "14px", lineHeight: "18px",

        color: "#000000", background: "#FFFFFF", border: "1px solid rgba(83, 93, 145, 0.12)"
    }
    const ValidationCellNoAlert1 = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            // size={'tiny'}
            open={validationCellNoAlert1}
        >
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>숫자만 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationCellNoAlert1(false);
                                setCELL_NO('');
                                mobileRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationCellNoAlert2 = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationCellNoAlert2}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>올바른 휴대폰 양식이 아닙니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationCellNoAlert2(false);
                                setCELL_NO('');
                                mobileRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationRecommNoAlert1 = function () {
        return (<Modal
          style={{width: "381px", height: "171px"}}
          centered={false}
          // size={'tiny'}
          open={validationRecommNoAlert1}
        >
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>숫자만 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationRecommNoAlert1(false);
                                setRECOMM_TEL('');
                                recommMobileRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationRecommNoAlert2 = function () {
        return (<Modal
          style={{width: "381px", height: "171px"}}
          centered={false}
          open={validationRecommNoAlert2}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>올바른 휴대폰 양식이 아닙니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationRecommNoAlert2(false);
                                setRECOMM_TEL('');
                                recommMobileRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationEmailAlert = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationEmailAlert}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>올바른 이메일 형식으로 입력해주세요.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationEmailAlert(false);
                                setEMAIL_ADDR('');
                                emailRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationOrgNmAlert = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationOrgNmAlert}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>기관명은 30자까지 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationOrgNmAlert(false);
                                setORG_NM('');
                                orgNmRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationManagerNmAlert = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationManagerNmAlert}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>담당자명은 20자까지 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationManagerNmAlert(false);
                                setMANAGER_NM('');
                                manageNameRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationContentAlert = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationContentAlert}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>문의내용은 1000자까지 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationContentAlert(false);
                                setCONTENT('');
                                contentRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }

    // 제출버튼 활성화 체크
    const commonValidationCheck = () => {
        // console.log("=====================================")
        // console.log("personalCheck", personalCheck);
        // console.log("ORG_NM", ORG_NM);
        // console.log("MANAGER_NM", MANAGER_NM);
        // console.log("phoneNumberFlag", phoneNumberFlag);
        // console.log("emailFlag", emailFlag);
        // console.log("=====================================")
        if (personalCheck && !isEmpty(ORG_NM) && !isEmpty(MANAGER_NM) && phoneNumberFlag && emailFlag && !isEmpty(MY_ADDR)) {
            // 제출 버튼 활성화
            document.getElementById("submitButtonId").style.backgroundColor = "#FF5A00";
            document.getElementById("submitButtonId").style.border = "1px solid #FF5A00";
            setPersonalButtonDisableState(false);
        } else {
            document.getElementById("submitButtonId").style.backgroundColor = "#9E9E9E";
            document.getElementById("submitButtonId").style.border = "1px solid #9E9E9E";
            // 제출 버튼 비활성화
            setPersonalButtonDisableState(true);
        }
    }

    const onConfirmClick = async () => {

        window.amplitude.track('[클릭] 상담접수하기');

        setCONFIRM_MANAGER_NM(MANAGER_NM);
        try {
            setShowLoading(true);

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/online-counsel-requests`, {
                ORG_NM,
                MANAGER_NM: encryptByPublicKey(MANAGER_NM),
                CELL_NO: encryptByPublicKey(CELL_NO),
                EMAIL_ADDR: encryptByPublicKey(EMAIL_ADDR),
                MY_ADDR: encryptByPublicKey(MY_ADDR),
                RECOMM_NM: encryptByPublicKey(RECOMM_NM),
                RECOMM_TEL: encryptByPublicKey(RECOMM_TEL),
                CONTENT,
                PERSONNEL_TYPE,
                UNIT_BUDGET,
                RCPT_DAY,
            });
            setConfirmOpen(true);

            sendNaverWcsLog();
        } catch (e) {
            alert(JSON.stringify(e.response.data.data.errors));
        } finally {
            setShowLoading(false);
        }
    };


    const mobileRef = useRef(null);
    const recommMobileRef = useRef(null);
    const regex = /^01([0|1|6|7|8|9]) ?([0-9]{3,4}) ?([0-9]{4})$/;
    const numberHandleBlur = (e) => {
        setPhoneNumberFlag(false);
        if (CELL_NO !== '') {
            if (!Number.isInteger(parseInt(CELL_NO.replace('0', '')))) {
                setValidationCellNoAlert1(true);
                return false;
            }

            if (!regex.test(CELL_NO.replace(/\s/g, ''))) {
                setValidationCellNoAlert2(true);
            } else {
                const regexPre = /^01([1|6|7|8|9])$/;
                if (!((CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) || (regexPre.test(CELL_NO.substring(0, 3)) && CELL_NO.replace(/\s/g, '').length === 10))) {
                    setValidationCellNoAlert2(true);
                } else {
                    setPhoneNumberFlag(true);
                }
            }
        }
    }
    const recommNumberHandleBlur = (e) => {
        if (RECOMM_TEL !== '') {
            if (!Number.isInteger(parseInt(RECOMM_TEL.replace('0', '')))) {
                setValidationRecommNoAlert1(true);
                return false;
            }

            if (!regex.test(RECOMM_TEL.replace(/\s/g, ''))) {
                setValidationRecommNoAlert2(true);
            } else {
                const regexPre = /^01([1|6|7|8|9])$/;
                if (!((RECOMM_TEL.startsWith('010') && RECOMM_TEL.replace(/\s/g, '').length === 11) || (regexPre.test(RECOMM_TEL.substring(0, 3)) && RECOMM_TEL.replace(/\s/g, '').length === 10))) {
                    setValidationRecommNoAlert2(true);
                }
            }
        }
    }
    // 휴대폰 번호 공백 효과
    React.useEffect(() => {
        if (regex.test(CELL_NO.replace(/\s/g, ''))) {
            if (CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3'));
            } else if (!CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 10) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3'));
            }
        }
    }, [CELL_NO]);

    //추천인 휴대폰 번호 공백 효과
    React.useEffect(() => {
        if (regex.test(RECOMM_TEL.replace(/\s/g, ''))) {
            if (RECOMM_TEL.startsWith('010') && RECOMM_TEL.replace(/\s/g, '').length === 11) {
                setRECOMM_TEL(RECOMM_TEL.replace(/\s/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3'));
            } else if (!RECOMM_TEL.startsWith('010') && RECOMM_TEL.replace(/\s/g, '').length === 10) {
                setRECOMM_TEL(RECOMM_TEL.replace(/\s/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3'));
            }
        }
    }, [RECOMM_TEL]);

    // Email validation
    const emailHandleBlur = () => {
        const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        setEmailFlag(false);
        if (EMAIL_ADDR !== '') {
            if (!regex.test(EMAIL_ADDR)) {
                setValidationEmailAlert(true);
            } else {
                setEmailFlag(true);
            }
        }
    }
    // 기관명 validation
    const orgNameOnChange = (e) => {
        if (e.target.value.length <= 30) {
            setORG_NM(e.target.value);
        } else {
            setValidationOrgNmAlert(true);
        }
    }

    // 글자수 방법 2
    const handleOnChange = (e, name, maxLength, cb) => {
        if (e.target.value.length <= maxLength) {
            cb(e.target.value);
        } else {
            alert(`${name}은 ${maxLength}자까지 입력 가능합니다.`);
        }
    }
    const handleOnManagerNmChange = (e) => {
        if (e.target.value.length <= 20) {
            setMANAGER_NM(e.target.value);
        } else {
            setValidationManagerNmAlert(true);
        }
    }

    const contentHandleOnChange = (e) => {
        if (e.target.value.length <= 1000) {
            setCONTENT_COUNT(e.target.value.length);
            setCONTENT(e.target.value);
        } else {
            setValidationContentAlert(true);
        }
    }

    // 제출버튼 활성화를 위한 체크
    React.useEffect(() => {
        commonValidationCheck();
    }, [ORG_NM]);
    React.useEffect(() => {
        commonValidationCheck();
    }, [MANAGER_NM]);
    React.useEffect(() => {
        commonValidationCheck();
    }, [phoneNumberFlag]);
    React.useEffect(() => {
        commonValidationCheck();
    }, [emailFlag]);

    const rcptDayChangeHandler = (optionIndex) => {
        const rcptTypeUpdatedCheckedState = rcptTypeCheckedState.map((item, index) => index === optionIndex ? !item : item);
        setRcptTypeCheckedState(rcptTypeUpdatedCheckedState);
        rcptTypeUpdatedCheckedState.forEach(function (day, idx) {
            rcptTypeStr = day === true ? (rcptTypeStr === "" ? rcptDayArray[idx] : rcptTypeStr + "," + rcptDayArray[idx]) : rcptTypeStr;
        });
        setRCPT_DAY(rcptTypeStr);
    }
    const renderPersonnelOption = () => {
        return (<Grid style={gridStyle}>
            <GridRow style={gridRowStyle} >
                <Grid.Column style={optionThStyle} verticalAlign="middle">인원</Grid.Column>
                <Grid.Column style={{width: 'auto'}}>
                        <span className="optionPersonnelType">
                            <Radio name="personnelType"
                                   value='5~20명 (GS25 픽업)'
                                   label='5~20명 (GS25 픽업)'
                                   checked={PERSONNEL_TYPE === '5~20명 (GS25 픽업)'}
                                   onChange={(e, {value}) => setPERSONNEL_TYPE(value)}/>
                        </span>
                    <span className="optionPersonnelType">
                            <Radio name="personnelType"
                                   value='20명 초과 (무료 배송)'
                                   label='20명 초과 (무료 배송)'
                                   checked={PERSONNEL_TYPE === '20명 초과 (무료 배송)'}
                                   onChange={(e, {value}) => setPERSONNEL_TYPE(value)}/>
                        </span>
                </Grid.Column>
            </GridRow>
        </Grid>)
    };
    const renderUnitBudgetOption = () => {
        return (<Grid style={gridStyle}>
            <Grid.Row style={gridRowStyle} >
                <Grid.Column style={optionThStyle} verticalAlign="middle">1회 예산(1인)</Grid.Column>
                <Grid.Column style={{width: 'auto'}}>
                        <span className="optionUnitBudget">
                                 <Radio name="unitBudget"
                                        value='3,000원~'
                                        label='3,000원~'
                                        checked={UNIT_BUDGET === '3,000원~'}
                                        onChange={(e, {value}) => setUNIT_BUDGET(value)}/>
                        </span>
                    <span className="optionUnitBudget">
                                 <Radio name="unitBudget"
                                        value='4,000원~'
                                        label='4,000원~'
                                        checked={UNIT_BUDGET === '4,000원~'}
                                        style={{marginRight: "4px"}}
                                        onChange={(e, {value}) => setUNIT_BUDGET(value)}/>
                            <Image src='./images/icon/icon_recommend.svg'
                            />
                        </span>
                    <span className="optionUnitBudget">
                                <Radio name="unitBudget"
                                       value='5,000원~'
                                       label='5,000원~'
                                       checked={UNIT_BUDGET === '5,000원~'}
                                       onChange={(e, {value}) => setUNIT_BUDGET(value)}/>
                        </span>
                    <span className="optionUnitBudget">
                               <Radio name="unitBudget"
                                      value='6,000원 이상'
                                      label='6,000원 이상'
                                      checked={UNIT_BUDGET === '6,000원 이상'}
                                      onChange={(e, {value}) => setUNIT_BUDGET(value)}/>
                        </span>
                </Grid.Column>
            </Grid.Row>
        </Grid>)
    };
    const renderRcptDayOption = () => {
        return (<Grid style={gridStyle}>
            <Grid.Row style={gridRowStyle} >
                <Grid.Column style={optionThStyle} verticalAlign="middle">희망 수령 요일</Grid.Column>
                <Grid.Column style={{width: 'auto'}}>
                        <span className="optionRcptDays">
                            <Checkbox name="rcptDay1"
                                      type="checkbox"
                                      value='월'
                                      label='월'
                                      checked={rcptTypeCheckedState[0]}
                                      onChange={() => rcptDayChangeHandler(0)}/>
                        </span>
                    <span className="optionRcptDays">
                            <Checkbox name="rcptDay2"
                                      type="checkbox"
                                      value='화'
                                      label='화'
                                      checked={rcptTypeCheckedState[1]}
                                      onChange={() => rcptDayChangeHandler(1)}/>
                        </span>
                    <span className="optionRcptDays">
                            <Checkbox name="rcptDay3"
                                      type="checkbox"
                                      value='수'
                                      label='수'
                                      checked={rcptTypeCheckedState[2]}
                                      onChange={() => rcptDayChangeHandler(2)}/>
                                </span>
                    <span className="optionRcptDays">
                            <Checkbox name="rcptDay4"
                                      type="checkbox"
                                      value='목'
                                      label='목'
                                      checked={rcptTypeCheckedState[3]}
                                      onChange={() => rcptDayChangeHandler(3)}/>
                        </span>
                    <span className="optionRcptDays">
                            <Checkbox name="rcptDay5"
                                      type="checkbox"
                                      value='금'
                                      label='금'
                                      checked={rcptTypeCheckedState[4]}
                                      onChange={() => rcptDayChangeHandler(4)}/>
                        </span>
                    <span className="optionRcptDays">
                            <Checkbox name="rcptDay6"
                                      type="checkbox"
                                      value='토'
                                      label='토'
                                      checked={rcptTypeCheckedState[5]}
                                      onChange={() => rcptDayChangeHandler(5)}/>
                        </span>
                    <span className="optionRcptDays">
                            <Checkbox name="rcptDay7"
                                      type="checkbox"
                                      value='일'
                                      label='일'
                                      checked={rcptTypeCheckedState[6]}
                                      onChange={() => rcptDayChangeHandler(6)}/>
                        </span>
                </Grid.Column>
            </Grid.Row>
        </Grid>)
    };

    return (<div className="onlineRequest">
        <ValidationCellNoAlert1/>
        <ValidationCellNoAlert2/>
        <ValidationRecommNoAlert1/>
        <ValidationRecommNoAlert2/>
        <ValidationEmailAlert/>
        <ValidationOrgNmAlert/>
        <ValidationManagerNmAlert/>
        <ValidationContentAlert/>

        <OnlineRequestConfirm content={CONFIRM_MANAGER_NM}/>
        <div id="request" className="titleDiv">
            <Image src={OnlineRequestLogo} style={{position: "absolute"}} width="100%"/>
        </div>
        <p className="info">
            ※상담이 접수되면 밀박스25 담당자가 24시간 내에 연락드립니다.</p>
        <Grid style={{width: "1050px", marginTop: "44px", marginLeft: "260px", marginRight: "260px"}}>
            <Grid.Row columns={2} style={gridRowStyle} >
                <Grid.Column columns={2}>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>기업명
                                <span style={{color: "red"}}>*</span>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <Input fluid placeholder='기업명 또는 기관명을 입력해주세요.'
                                       value={ORG_NM}
                                       ref={orgNmRef}
                                       tabIndex={1}
                                       style={inputStyle}
                                       onChange={(e) => orgNameOnChange(e)}/>
                            </td>
                        </tr>
                        <tr>
                            <th style={tableThStyle}>연락처
                                <span style={{color: "red"}}>*</span>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <Input type="text" fluid
                                       placeholder="'-' 없이 번호만 입력해주세요."
                                       value={CELL_NO}
                                       ref={mobileRef}
                                       tabIndex={3}
                                       style={inputStyle}
                                       onChange={(e) => setCELL_NO(e.target.value)} onBlur={numberHandleBlur}/>
                            </td>
                        </tr>
                        <tr>
                            <th style={tableThStyle}>추천인 이름 / 기업명</th>
                        </tr>
                        <tr>
                            <td>
                                <Input type="text" fluid
                                       placeholder="추천인 정보를 입력해주세요."
                                       value={RECOMM_NM}
                                       tabIndex={5}
                                       style={inputStyle}
                                       onChange={(e) => setRECOMM_NM(e.target.value)} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
                <Grid.Column columns={2}>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>담당자명
                                <span style={{color: "red"}}>*</span>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Input fluid placeholder='담당자명을 입력해주세요.'
                                       value={MANAGER_NM}
                                       ref={manageNameRef}
                                       tabIndex={2}
                                       style={inputStyle}
                                       onChange={(e) => handleOnManagerNmChange(e)}/>
                            </td>
                        </tr>
                        <tr>
                            <th style={tableThStyle}>이메일 주소
                                <span style={{color: "red"}}>*</span>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Input fluid placeholder='이메일 주소를 입력해주세요.'
                                       value={EMAIL_ADDR}
                                       ref={emailRef}
                                       tabIndex={4}
                                       style={inputStyle}
                                       onChange={(e) => setEMAIL_ADDR(e.target.value)}
                                       onBlur={emailHandleBlur}/>
                            </td>
                        </tr>
                        <tr>
                            <th style={tableThStyle}>추천인 연락처</th>
                        </tr>
                        <tr>
                            <td>
                                <Input type="text" fluid
                                       placeholder="'-' 없이 번호만 입력해주세요."
                                       ref={recommMobileRef}
                                       value={RECOMM_TEL}
                                       tabIndex={6}
                                       style={inputStyle}
                                       onChange={(e) => setRECOMM_TEL(e.target.value)}
                                       onBlur={recommNumberHandleBlur}/>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
            {/*희망 배송지 (기업주소)*/}
            <Grid.Row style={gridRowStyle}>
                <Grid.Column>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>희망 배송지 (기업주소)<span style={{color: "red"}}>*</span></th>
                        </tr>
                        <tr>
                            <td style={{width: "100%"}}>
                                <Input fluid
                                       value={MY_ADDR}
                                       tabIndex={8}
                                       style={inputStyle2}
                                       disabled={true}
                                       onChange={(e) => handleOnChange(e, '배송희망 지역', 100, setMY_ADDR)}/>
                            </td>
                            <td>
                                <SearchAddr content='주소 찾기' retFunc={setMY_ADDR} isPc={true}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={gridRowStyle}  >
                <Grid.Column columns={1}>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>상담 기본정보</th>
                        </tr>
                        <tr>
                            <td>
                                <Card style={{width: "100%", borderRadius: '8px'}}>
                                    <Card.Content
                                        style={{
                                            // ::after 가상 요소 때문에 padding bottom을 4px로 변경
                                            padding: "22px 0 4px 22px",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "18px",
                                        }}
                                    >
                                        {renderPersonnelOption()}
                                        {renderUnitBudgetOption()}
                                        {renderRcptDayOption()}
                                    </Card.Content>
                                </Card>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{...gridRowStyle, marginTop: '24px'}} >
                <Grid.Column style={{width: "100%"}}>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>문의내용</th>
                            <td className="thOption3">{CONTENT_COUNT} / 1000
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                    <textarea placeholder="상세 문의내용을 입력해주세요. &#13;&#10;※ 개인정보 보호를 위해 문의내용 외 개인정보를 입력하지 않도록 유의해 주시기 바랍니다."
                                              style={textAreaStyle}
                                              value={CONTENT}
                                              ref={contentRef}
                                              onChange={(e) => contentHandleOnChange(e)}>
                                    </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <input type="checkbox"
                                           id="lebelCheck"
                                        //onClick={personalCheckboxClick}
                                           checked={personalCheck}
                                           onChange={(e) => {
                                               setPersonalCheck(!personalCheck);
                                           }}
                                    />
                                    <label className="personalCheckLabel" htmlFor="lebelCheck">
                                        (필수) 개인정보 수집·이용 동의
                                    </label>
                                    <PersonalInformModal content='전문보기'/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <div style={{textAlign: "center", marginBottom: "120px"}}>
            {showLoading ? <ClipLoader
                loading={showLoading}
                cssOverride={{
                    display: "block", margin: "0 auto", borderColor: "grey", backgroundColor: "transparent",
                }}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> : <Button type="button"
                         id="submitButtonId"
                         onClick={onConfirmClick}
                         style={submitBtnStyle}
                         data-sc-action='button.click'
                         data-sc-value='상담 접수하기'
                         disabled={personalButtonDisableState}>상담 접수하기
            </Button>}
        </div>
    </div>);
}

export const RequestFormMo = () => {

    const submitBtnStyle = {
        marginTop: "50px",
        width: "290px",
        height: "64px",
        border: "1px solid #9E9E9E",
        backgroundColor: "#9E9E9E",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontFamily: "Noto Sans KR",
        fontWeight: "bold",
        fontSize: "16px"
    }

    const optionThStyle = {
        fontFamily: "Noto Sans KR", fontWeight: "bold", fontSize: "14px", textAlign: "left"
    }

    const textAreaStyle = {
        width: "100%",
        minHeight: "112px",
        resize: "none",
        borderRadius: "8px",
        border: "1px solid #d4d4d5",
        fontFamily: "Noto Sans KR",
        padding: "12px",
        marginBottom: "14px"
    }

    const mobileInputStyle = {
        marginBottom: "24px", height: "48px",
    }
    const mobileInputStyle2 = {
        marginBottom: "24px",
        height: "48px",
        width: "100%",
        backgroundColor: "#ECECEC",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxSizing: "border-box",
        borderRadius: "8px",


    }

    const [showLoading, setShowLoading] = React.useState(false);
    const [personalCheck, setPersonalCheck] = React.useState(false);
    const [personalButtonDisableState, setPersonalButtonDisableState] = React.useState(true);

    const [ORG_NM, setORG_NM] = React.useState('');
    const [MANAGER_NM, setMANAGER_NM] = React.useState('');
    const [CONFIRM_MANAGER_NM, setCONFIRM_MANAGER_NM] = React.useState('');
    const [CELL_NO, setCELL_NO] = React.useState('');
    const [EMAIL_ADDR, setEMAIL_ADDR] = React.useState('');
    const [MY_ADDR, setMY_ADDR] = React.useState('');
    const [CONTENTMO, setCONTENTMO] = React.useState('');
    const [CONTENT_COUNTMo, setCONTENT_COUNTMo] = React.useState(0);
    const [RECOMM_NM, setRECOMM_NM] = React.useState('');
    const [RECOMM_TEL, setRECOMM_TEL] = React.useState('');

    const [UNIT_BUDGET, setUNIT_BUDGET] = React.useState('');
    const [PERSONNEL_TYPE, setPERSONNEL_TYPE] = React.useState('');
    const [RCPT_DAY, setRCPT_DAY] = React.useState('');

    const [confirmOpenMo, setConfirmOpenMo] = React.useState(false);

    const [phoneNumberFlagMo, setPhoneNumberFlagMo] = React.useState(false);
    const [emailFlagMo, setEmailFlagMo] = React.useState(false);

    // 제출버튼 활성화 체크
    const commonValidationCheckMo = () => {
        // console.log("=====================================")
        // console.log("personalCheck", personalCheck);
        // console.log("ORG_NM", ORG_NM);
        // console.log("MANAGER_NM", MANAGER_NM);
        // console.log("phoneNumberFlagMo", phoneNumberFlagMo);
        // console.log("emailFlagMo", emailFlagMo);
        // console.log("RecommenderTitle", RecommenderTitle);
        // console.log("=====================================")
        if (personalCheck && !isEmpty(ORG_NM) && !isEmpty(MANAGER_NM) && phoneNumberFlagMo && emailFlagMo && !isEmpty(MY_ADDR)) {
            // 제출 버튼 활성화
            document.getElementById("submitButtonId").style.backgroundColor = "#FF5A00";
            document.getElementById("submitButtonId").style.border = "1px solid #FF5A00";
            setPersonalButtonDisableState(false);
        } else {
            document.getElementById("submitButtonId").style.backgroundColor = "#9E9E9E";
            document.getElementById("submitButtonId").style.border = "1px solid #9E9E9E";
            // 제출 버튼 비활성화
            setPersonalButtonDisableState(true);
        }
    }

    React.useEffect(() => {
        commonValidationCheckMo();
        // if (personalCheck === true) {
        //     // 제출 버튼 활성화 (
        //     setPersonalButtonDisableState(false);
        // } else {
        //     // 제출 버튼 비활성화
        //     setPersonalButtonDisableState(true);
        // }
    }, [personalCheck, ORG_NM, MANAGER_NM, phoneNumberFlagMo, emailFlagMo, MY_ADDR]);

    const orgNmRef = useRef(null);
    const manageNameRef = useRef(null);
    const emailRef = useRef(null);

    let rcptTypeStr = "";
    const rcptDayArray = ["월", "화", "수", "목", "금", "토", "일"];
    const [rcptTypeCheckedState, setRcptTypeCheckedState] = React.useState(new Array(7).fill(false));

    const OnlineRequestConfirmMo = function ({content}) {
        const confirmStyleMo = {
            textAlign: "center",
            fontFamily: "Noto Sans KR",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "28px",
            letterSpacing: "-0.4px",
            color: "#000000"
        }
        const confirmHeader = {
            fontFamily: "Noto Sans KR",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000"
        }
        const confirmImgDivStyle = {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "24px",
            marginBottom: "24px",
            textAlign: "center",
        }
        const confirmButtonActionStyle = {
            textAlign: "center", marginTop: "26px", padding: "15px 147px 15px 147px", background: "#FFFFFF"
        }
        const confirmButtonStyle = {
            background: "#FFFFFF",
            fontFamily: "Noto Sans KR",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000",
            border: "0px",

            width: "30px",
            height: "18px",
            padding: "0px", // margin: "15px 0 15px 0"
        }

        return (<Modal
            style={{width: "320px", height: "375px"}}
            open={confirmOpenMo}
        >
            <Modal.Content>
                <p style={confirmHeader}>상담접수완료</p>
                <div style={confirmImgDivStyle}>
                    <Image src='./images/icon/icon_confirmCheck.svg'
                           style={{width: "60px", height: "60px"}}
                    />
                </div>
                <div style={confirmStyleMo}>
                    <p>감사합니다.</p>
                    <p><span style={{color: "#FF5A00"}}>{content}</span>님의 상담 접수 신청이 완료되었습니다.</p>
                    <p>빠른 시간 안에 연락 드리도록 하겠습니다.</p>
                    <p>(영업일 기준 24시간 이내)</p>
                </div>
            </Modal.Content>
            <Modal.Actions style={confirmButtonActionStyle}>
                <button style={confirmButtonStyle}
                        onClick={() => {
                            window.location.reload();
                        }}>
                    확인
                </button>
            </Modal.Actions>

        </Modal>);
    }

    const onConfirmClick = async () => {

        window.amplitude.track('[클릭] 상담접수하기');

        setCONFIRM_MANAGER_NM(MANAGER_NM);

        try {
            setShowLoading(true);

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/online-counsel-requests`, {
                ORG_NM,
                MANAGER_NM: encryptByPublicKey(MANAGER_NM),
                CELL_NO: encryptByPublicKey(CELL_NO),
                EMAIL_ADDR: encryptByPublicKey(EMAIL_ADDR),
                MY_ADDR: encryptByPublicKey(MY_ADDR),
                RECOMM_NM: encryptByPublicKey(RECOMM_NM),
                RECOMM_TEL: encryptByPublicKey(RECOMM_TEL),
                CONTENT: CONTENTMO,
                PERSONNEL_TYPE,
                UNIT_BUDGET,
                RCPT_DAY,
            });

            setConfirmOpenMo(true);

            sendNaverWcsLog();


        } catch (e) {
            alert(JSON.stringify(e.response.data.data.errors));
        } finally {

            setShowLoading(false);

        }
    };


    const mobileRef = useRef(null);
    const recommMobileRef = useRef(null);
    const regex = /^01([0|1|6|7|8|9]) ?([0-9]{3,4}) ?([0-9]{4})$/;
    const numberHandleBlur = (e) => {
        setPhoneNumberFlagMo(false);
        if (CELL_NO !== '') {
            if (!Number.isInteger(parseInt(CELL_NO.replace('0', '')))) {
                alert('숫자만 입력 가능합니다.');
                setCELL_NO('');
                mobileRef.current.focus();
                return false;
            }

            if (!regex.test(CELL_NO.replace(/\s/g, ''))) {
                alert('올바른 휴대폰 양식이 아닙니다.');
                setCELL_NO('');
                mobileRef.current.focus();
            } else {
                const regexPre = /^01([1|6|7|8|9])$/;
                if (!((CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) || (regexPre.test(CELL_NO.substring(0, 3)) && CELL_NO.replace(/\s/g, '').length === 10))) {
                    alert('올바른 휴대폰 양식이 아닙니다.');
                    setCELL_NO('');
                    mobileRef.current.focus();
                } else {
                    setPhoneNumberFlagMo(true);
                }
            }
        }
    }
    const recommNumberHandleBlur = () => {
        if (RECOMM_TEL !== '') {
            if (!Number.isInteger(parseInt(RECOMM_TEL.replace('0', '')))) {
                alert('숫자만 입력 가능합니다.');
                setRECOMM_TEL('');
                recommMobileRef.current.focus();
                return false;
            }

            if (!regex.test(RECOMM_TEL.replace(/\s/g, ''))) {
                alert('올바른 휴대폰 양식이 아닙니다.');
                setRECOMM_TEL('');
                recommMobileRef.current.focus();
            } else {
                const regexPre = /^01([1|6|7|8|9])$/;
                if (!((RECOMM_TEL.startsWith('010') && RECOMM_TEL.replace(/\s/g, '').length === 11) || (regexPre.test(RECOMM_TEL.substring(0, 3)) && RECOMM_TEL.replace(/\s/g, '').length === 10))) {
                    alert('올바른 휴대폰 양식이 아닙니다.');
                    setRECOMM_TEL('');
                    recommMobileRef.current.focus();
                }
            }
        }
    }

// 휴대폰 번호 공백 효과
    React.useEffect(() => {

        if (regex.test(CELL_NO.replace(/\s/g, ''))) {
            if (CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3'));
            } else if (!CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 10) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3'));
            }
        }
    }, [CELL_NO]);

//추천인 휴대폰 번호 공백 효과
    React.useEffect(() => {
        if (regex.test(RECOMM_TEL.replace(/\s/g, ''))) {
            if (RECOMM_TEL.startsWith('010') && RECOMM_TEL.replace(/\s/g, '').length === 11) {
                setRECOMM_TEL(RECOMM_TEL.replace(/\s/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3'));
            } else if (!RECOMM_TEL.startsWith('010') && RECOMM_TEL.replace(/\s/g, '').length === 10) {
                setRECOMM_TEL(RECOMM_TEL.replace(/\s/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3'));
            }
        }
    }, [RECOMM_TEL]);

// Email validation
    const emailHandleBlur = () => {
        const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        setEmailFlagMo(false);
        if (EMAIL_ADDR !== '') {
            if (!regex.test(EMAIL_ADDR)) {
                alert('올바른 이메일 형식으로 입력해주세요.');
                setEMAIL_ADDR('');
                emailRef.current.focus();
            } else {
                setEmailFlagMo(true);
            }
        }
    }
// 기관명 validation
    const orgNameOnChange = (e) => {
        if (e.target.value.length <= 30) {
            setORG_NM(e.target.value);
        } else {
            alert(`기관명은 30자까지 입력 가능합니다.`);
        }
    }

    // 제출버튼 활성화를 위한 체크
    React.useEffect(() => {
        commonValidationCheckMo();
    }, [ORG_NM]);
    React.useEffect(() => {
        commonValidationCheckMo();
    }, [MANAGER_NM]);
    React.useEffect(() => {
        commonValidationCheckMo();
    }, [phoneNumberFlagMo]);
    React.useEffect(() => {
        commonValidationCheckMo();
    }, [emailFlagMo]);

    // 글자수 방법 2
    const handleOnChange = (e, name, maxLength, cb) => {
        if (e.target.value.length <= maxLength) {
            cb(e.target.value);
        } else {
            alert(`${name}은 ${maxLength}자까지 입력 가능합니다.`);
        }
    }

    const CONTENT_MOOnChange = (e) => {
        if (e.target.value.length <= 1000) {
            setCONTENT_COUNTMo(e.target.value.length);
            setCONTENTMO(e.target.value);
        } else {
            alert(`문의내용은 1000자까지 입력 가능합니다.`);
        }
    }

    const rcptDayChangeHandler = (optionIndex) => {
        const rcptTypeUpdatedCheckedState = rcptTypeCheckedState.map((item, index) => index === optionIndex ? !item : item);
        setRcptTypeCheckedState(rcptTypeUpdatedCheckedState);
        rcptTypeUpdatedCheckedState.forEach(function (day, idx) {
            rcptTypeStr = day === true ? (rcptTypeStr === "" ? rcptDayArray[idx] : rcptTypeStr + "," + rcptDayArray[idx]) : rcptTypeStr;
        });
        setRCPT_DAY(rcptTypeStr);
    }

    // const dlvrTypeOptions = [{key: '0', value: ' ', text: '선택해주세요.'}, {
    //     key: '1', value: 'GS25 점포픽업', text: 'GS25 점포픽업'
    // }, {key: '2', value: '정기배송(최소20人 이상)', text: '정기배송(최소20人 이상)'}];

    const personnelOption = [{key: '0', value: ' ', text: '선택해주세요.'}, {
        key: '1', value: '5~20명 (GS25 픽업)', text: '5~20명 (GS25 픽업)'
    }, {key: '2', value: '20명 초과 (무료 배송)', text: '20명 초과 (무료 배송)'}]

    const unitBudgetOptions = [{key: '0', value: ' ', text: '선택해주세요.'}, {
        key: '1', value: '3,000원~', text: '3,000원~'
    }, {key: '2', value: '4,000원~', text: '4,000원~ (추천)'}, {key: '3', value: '5,000원~', text: '5,000원~'}, {
        key: '4', value: '6,000원 이상', text: '6,000원 이상'
    },];

    const renderPersonnelOption = () => {
        return (<div style={{width: "100%"}}>
            <table className="mobileOptionTable">
                <tbody>
                <tr>
                    <th style={optionThStyle}>인원</th>
                </tr>
                <tr>
                    <td>
                        <Select fluid placeholder='선택해주세요.'
                                options={personnelOption}
                                value={PERSONNEL_TYPE}
                                onChange={(e, data) => setPERSONNEL_TYPE(data.value)}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>)
    };
    const renderUnitBudgetOption = () => {
        return (<div style={{width: "100%"}}>
            <table className="mobileOptionTable">
                <tbody>
                <tr>
                    <th style={optionThStyle}>1회 예산(1인)</th>
                </tr>
                <tr>
                    <td>
                        <Select fluid placeholder='선택해주세요.'
                                options={unitBudgetOptions}
                                value={UNIT_BUDGET}
                                onChange={(e, data) => setUNIT_BUDGET(data.value)}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>)
    };
    const renderRcptDayOption = () => {
        return (<div style={{margin: "16px 0 0 16px"}}>
            <table style={{paddingBottom: "16px"}} >
                <tbody >
                <tr>
                    <th style={optionThStyle}>희망 수령 요일</th>
                </tr>
                <tr>
                    <td style={{width: "50em"}}>
                        <Grid columns={3}>
                            <GridRow>
                                <GridColumn>
                                    <Checkbox name="rcptDay1"
                                              type="checkbox"
                                              value='월'
                                              label='월'
                                              checked={rcptTypeCheckedState[0]}
                                              style={{marginTop: "8px", marginBottom: "12px"}}
                                              onChange={() => rcptDayChangeHandler(0)}/>
                                </GridColumn>
                                <GridColumn>
                                    <Checkbox name="rcptDay2"
                                              type="checkbox"
                                              value='화'
                                              label='화'
                                              checked={rcptTypeCheckedState[1]}
                                              style={{marginTop: "8px", marginBottom: "12px"}}
                                              onChange={() => rcptDayChangeHandler(1)}/>
                                </GridColumn>
                                <GridColumn>
                                    <Checkbox name="rcptDay3"
                                              type="checkbox"
                                              value='수'
                                              label='수'
                                              checked={rcptTypeCheckedState[2]}
                                              style={{marginTop: "8px", marginBottom: "12px"}}
                                              onChange={() => rcptDayChangeHandler(2)}/>
                                </GridColumn>
                                <GridColumn>
                                    <Checkbox name="rcptDay4"
                                              type="checkbox"
                                              value='목'
                                              label='목'
                                              checked={rcptTypeCheckedState[3]}
                                              style={{marginBottom: "12px"}}
                                              onChange={() => rcptDayChangeHandler(3)}/>
                                </GridColumn>
                                <GridColumn>
                                    <Checkbox name="rcptDay5"
                                              type="checkbox"
                                              value='금'
                                              label='금'
                                              checked={rcptTypeCheckedState[4]}
                                              style={{marginBottom: "12px"}}
                                              onChange={() => rcptDayChangeHandler(4)}/>
                                </GridColumn>
                                <GridColumn>
                                    <Checkbox name="rcptDay6"
                                              type="checkbox"
                                              value='토'
                                              label='토'
                                              checked={rcptTypeCheckedState[5]}
                                              style={{marginBottom: "12px"}}
                                              onChange={() => rcptDayChangeHandler(5)}/>
                                </GridColumn>
                                <GridColumn>
                                    <Checkbox name="rcptDay7"
                                              type="checkbox"
                                              value='일'
                                              label='일'
                                              checked={rcptTypeCheckedState[6]}
                                              onChange={() => rcptDayChangeHandler(6)}/>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>)
    };


    return (<div className="onlineRequestMo">
        <OnlineRequestConfirmMo content={CONFIRM_MANAGER_NM}/>
        <div id="request" className="titleDivMo">
            <Image src={OnlineRequestMoLogo}/>
        </div>
        <div className="info-mo">
            <label>※상담이 접수되면 밀박스25 담당자가</label>
            <label>24시간 내에 연락드립니다.</label>
        </div>
        <div className="table-div">
            <table style={{width: "100%"}}>
                <tbody>
                <tr>
                    <th className="table-thMo">
                        <label>기업명</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder='기업명 또는 기관명을 입력해주세요.'
                               value={ORG_NM}
                               ref={orgNmRef}
                               style={mobileInputStyle}
                               onChange={(e) => orgNameOnChange(e)}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>담당자명</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder='담당자명을 입력해주세요.'
                               value={MANAGER_NM}
                               ref={manageNameRef}
                               style={mobileInputStyle}
                               onChange={(e) => handleOnChange(e, '담당자명', 20, setMANAGER_NM)}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>연락처</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input type="text" fluid placeholder="'-' 없이 번호만 입력해주세요."
                               value={CELL_NO}
                               ref={mobileRef}
                               style={mobileInputStyle}
                               onChange={(e) => setCELL_NO(e.target.value)}
                               onBlur={numberHandleBlur}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>이메일 주소</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder='이메일 주소를 입력해주세요.'
                               value={EMAIL_ADDR}
                               ref={emailRef}
                               style={mobileInputStyle}
                               onChange={(e) => setEMAIL_ADDR(e.target.value)} onBlur={emailHandleBlur}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>추천인 이름 / 기업명</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder="추천인 정보를 입력해주세요."
                               value={RECOMM_NM}
                               style={mobileInputStyle}
                               onChange={(e) => setRECOMM_NM(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>추천인 연락처</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder="'-' 없이 번호만 입력해주세요."
                               value={RECOMM_TEL}
                               style={mobileInputStyle}
                               ref={recommMobileRef}
                               onChange={(e) => setRECOMM_TEL(e.target.value)}
                               onBlur={recommNumberHandleBlur}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>희망 배송지 (기업주소)<span style={{color: "red"}}>*</span></label>
                    </th>
                </tr>
                <tr>
                    <td className="thOptionMo2" style={{width: '100%'}}>
                        <Input fluid
                               value={MY_ADDR}
                               style={mobileInputStyle2}
                               disabled={true}
                          // readOnly={true}
                               onChange={(e) => handleOnChange(e, '배송희망 지역', 100, setMY_ADDR)}/>
                    </td>
                    <td style={{paddingRight: '16px'}}>
                        <SearchAddrMo content='주소 찾기' retFunc={setMY_ADDR} isPc={false}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">상담 기본정보</th>
                </tr>
                <tr>
                    <td colSpan="2" className="optionsTdMo">
                        <Card style={{
                            width: "100%",
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            boxSizing: "border-box",
                            borderRadius: "8px",
                            boxShadow: "0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5"
                        }}>
                            <Card.Content>
                                <Grid>
                                    {renderPersonnelOption()}
                                    {renderUnitBudgetOption()}
                                    {renderRcptDayOption()}
                                </Grid>
                            </Card.Content>
                        </Card>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style={{width: "100%"}}>
            <table style={{width: "100%"}}>
                <tbody>
                <tr>
                    <th className="table-thMo">문의내용</th>
                    <td className="thOptionMo3">{CONTENT_COUNTMo} / 1000</td>
                </tr>
                <tr>
                    <td colSpan="2" className="thOptionMo">
                                <textarea placeholder='상세 문의내용을 입력해주세요. &#13;&#10;※ 개인정보 보호를 위해 문의내용 외 개인정보를 입력하지 않도록 유의해 주시기 바랍니다.'
                                          style={textAreaStyle}
                                          value={CONTENTMO}
                                          onChange={(e) => CONTENT_MOOnChange(e)}>
                                </textarea>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div className="personalCheckDiv">
            <input type="checkbox"
                //onClick={personalCheckboxClick}
                   id="lebelCheck"
                   checked={personalCheck}
                   onChange={(e) => {
                       if (personalButtonDisableState === true) {
                           document.getElementById("submitButtonId").style.backgroundColor = "#FF5A00";
                           document.getElementById("submitButtonId").style.border = "1px solid #FF5A00";

                       } else {
                           document.getElementById("submitButtonId").style.backgroundColor = "#9E9E9E";
                           document.getElementById("submitButtonId").style.border = "1px solid #9E9E9E";
                       }
                       setPersonalCheck(!personalCheck);
                   }}
            />
            <label className="personalCheckLabel" htmlFor="lebelCheck">(필수) 개인정보 수집·이용 동의</label>
            <PersonalInformModalMo content='전문보기'/>
        </div>
        <div style={{textAlign: "center", marginBottom: "108px"}}>
            {showLoading ? <ClipLoader
                loading={showLoading}
                cssOverride={{
                    display: "block", margin: "0 auto", borderColor: "grey", backgroundColor: "transparent",
                }}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> : <Button type="button"
                         id="submitButtonId"
                         onClick={onConfirmClick}
                         style={submitBtnStyle}
                         data-sc-action='button.click'
                         data-sc-value='상담 접수하기'
                         disabled={personalButtonDisableState}>상담 접수하기
            </Button>}
        </div>


    </div>);
}
