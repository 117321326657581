import "./ServiceIntro2.css";

export const ServiceIntro2 = () => {
    return (
        <div className="intro2">
            <div id="menu" className="title">
                <label>예산별 식단</label>
            </div>
            <div className="content">
                <label>고객사 예산에 맞는 식단을 설계하여</label>
                <label>매월 다양하고 새로운 구성을 제안해 드립니다.</label>
            </div>
            <div className="info">
                <img src="./images/icon/icon_info.svg"  style={{width: "24px", height: "24px", verticalAlign: "middle"}}/>
                <label className="info-content">아래 상품과 식단은 예시이니 구성 선택에 참고해 주세요.</label>
            </div>
            <div className="cards">
                <img src="./images/main_menu.png" alt="" style={{width: "950px", height: "848px"}}/>
            </div>
        </div>
    );
};

export const ServiceIntro2Mo = () => {
    return (
        <div className="intro2-mo">
            <div id="menu" className="title">
                <label>예산별 식단</label>
            </div>
            <div className="content">
                <label>고객사 예산에 맞는 식단을 설계하여</label>
                <label>매월 다양하고 새로운 구성을 제안해 드립니다.</label>
            </div>
            <div className="infoMenuMo">
                <img src="./images/icon/icon_info.svg"  style={{width: "16px", height: "16.34px", verticalAlign: "middle"}}/>
                <label className="info-contentMo">아래 상품과 식단은 예시이니 구성 선택에 참고해 주세요.</label>
            </div>
            <div className="cards">
                <div>
                    <img src="./images/mo_main_menu_01.png" alt="" style={{width: "328px", height: "420px"}}/>
                </div>
                <div className="infoMenuItem">
                    <img src="./images/mo_main_menu_02.png" alt="" style={{width: "328px", height: "452px"}}/>
                </div>
                <div className="infoMenuItem">
                    <img src="./images/mo_main_menu_03.png" alt="" style={{width: "328px", height: "452px"}}/>
                </div>
                <div className="infoMenuLastItem">
                    <img src="./images/mo_main_menu_04.png" alt="" style={{width: "328px", height: "248px"}}/>
                </div>
            </div>
        </div>
    );
};