import {Image} from "semantic-ui-react";
import React from "react";
import "./ServiceProcess.css"
import MealBox25Logo from '../assets/logo_process.svg';
import Step1 from '../assets/step1.svg';
import Step2 from '../assets/step2.svg';
import Step3 from '../assets/step3.svg';
import Step4 from '../assets/step4.svg';
import Step5 from '../assets/step5.svg';
import Step6 from '../assets/step6.svg';

export const ServiceProcess = () => (
    <div className="process">
        <div className="logo">
            <Image src={MealBox25Logo}></Image>
        </div>
        <div className="content">
            <label>기업 맞춤형 식사, 간편식 구매부터 배송, 진열까지 관리하는</label>
            <label>GS리테일의 B2B전용 ONE-STOP MEAL CARE SERVICE입니다.</label>
        </div>
        <div className="steps">
            <Image className="step" src={Step1}/>
            <Image className="step" src={Step2}/>
            <Image className="step" src={Step3}/>
            <Image className="step" src={Step4}/>
            <Image className="step" src={Step5}/>
            <Image className="step" src={Step6}/>
        </div>
    </div>
);

export const ServiceProcessMo = () => (
    <div className="process-mo">
        <div className="logo">
            <Image src={MealBox25Logo}></Image>
        </div>
        <div className="content">
            <label>기업 맞춤형 식사, 간편식 구매부터</label>
            <label>배송, 진열까지 관리하는</label>
            <label>GS리테일의 B2B전용 ONE-STOP</label>
            <label>MEAL CARE SERVICE입니다.</label>
        </div>
        <div className="steps">
            <Image className="step" src={Step1}/>
            <Image className="step" src={Step2}/>
            <Image className="step" src={Step3}/>
        </div>
        <div className="steps">
            <Image className="step" src={Step4}/>
            <Image className="step" src={Step5}/>
            <Image className="last-step" src={Step6}/>
        </div>
    </div>
);