import React from "react";
import "./ServiceIntro3.css"

export const ServiceIntro3 = () => {
    return (
        <div className="intro3">
            <div id="rent" className="title">
                <label>무상 대여</label>
            </div>
            <div className="content">
                <label>고객사 환경을 고려한 맞춤형 설비를 제공합니다.</label>
                <label>3개월 이상 장기구독 고객사에는 필요한 설비를 무상으로 제공합니다.</label>
            </div>
            <div className="cards">
                <div className="card">
                    <img src="./images/intro3_01.png" alt=""  style={{width: "880px", height: "400px"}}/>
                </div>
            </div>
        </div>
    );
};

export const ServiceIntro3Mo = () => {
    return (
        <div className="intro3-mo">
            <div id="rent" className="title">
                <label>무상 대여</label>
            </div>
            <div className="content">
                <label>고객사 환경을 고려한 맞춤형 설비를 제공합니다.</label>
                <label>3개월 이상 장기구독 고객사에는</label>
                <label>필요한 설비를 무상으로 제공합니다.</label>
            </div>
            <div className="cards">
                <div className="card">
                    <img src="./images/intro3_01_mo.png" alt="" style={{width: "328px", height: "366px"}}/>
                </div>
            </div>
        </div>
    );
};