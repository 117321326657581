import React, {useEffect, useRef} from "react";
import 'semantic-ui-css/semantic.min.css';
import {useMediaQuery} from "react-responsive"

import {Faq, FaqMo} from "./view/Faq";
import {RequestForm, RequestFormMo} from "./view/OnlineRequest";
import "./App.css";
import {ServiceGNB, ServiceGNBMo} from "./view/ServiceGnb";
import {ServiceIntro, ServiceIntroMo} from "./view/ServiceIntro";
import {ServiceBenefit, ServiceBenefitMo} from "./view/ServiceBenefit";
import {ServiceProcess, ServiceProcessMo} from "./view/ServiceProcess";
import {ServiceFeature, ServiceFeatureMo} from "./view/ServiceFeature";
import {ServiceIntro1, ServiceIntro1Mo} from "./view/ServiceIntro1";
import {ServiceIntro2, ServiceIntro2Mo} from "./view/ServiceIntro2";
import {ServiceIntro3, ServiceIntro3Mo} from "./view/ServiceIntro3";
import ServiceCustomer from "./view/ServiceCustomer";
import {Footer, FooterMo} from "./view/Footer";
import {GoToTop, GoToTopMo} from "./view/GoToTop";
import {PopupBanner, PopupBannerMo} from "./view/PopupBanner";

const App = () => {
  const isPc = useMediaQuery({
    query: "(min-width:1000px)"
  });
  const isMobile = useMediaQuery({
    query: "(max-width:999px)"
  });

  const appRef = useRef(null);

  // 처음 로딩시 페이지뷰 통계 스크립트 실행
  useEffect(() => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[뷰] 밀박스25페이지');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }
  }, []);

  // 처음 로딩시 페이지뷰 통계 스크립트 실행
  useEffect(() => {
    try {

    } catch (e) {
    }
  }, []);


  const contentChildren = (menuVisible) => {
    return (<>
      {isPc && <PopupBanner/>}
      {isMobile && <PopupBannerMo/>}
      {/* Goto Top Btn */}
      {isPc && <GoToTop appRef={appRef}/>}
      {isMobile && <GoToTopMo appRef={appRef} menuVisible={menuVisible}/>}

      {/* 서비스 소개 */}
      {isPc && <ServiceIntro/>}
      {isMobile && <ServiceIntroMo/>}

      {/* 서비스 혜택 안내 */}
      {isPc && <ServiceBenefit/>}
      {isMobile && <ServiceBenefitMo/>}

      {/* 서비스 프로세스 안내 */}
      {isPc && <ServiceProcess/>}
      {isMobile && <ServiceProcessMo/>}

      {/* 서비스 홍보 동영상 및 서비스 특징 */}
      {isPc && <ServiceFeature/>}
      {isMobile && <ServiceFeatureMo/>}

      {/* 서비스 소개1: 맞춤형 식단 제안 */}
      {isPc && <ServiceIntro1/>}
      {isMobile && <ServiceIntro1Mo/>}

      {/* 서비스 소개2: 메뉴 구성 */}
      {isPc && <ServiceIntro2/>}
      {isMobile && <ServiceIntro2Mo/>}

      {/* 서비스 소개3: 스낵바 */}
      {/*{<ServiceSnackbar/>}*/}

      {/* 서비스 소개3: 무상대여 */}
      {isPc && <ServiceIntro3/>}
      {isMobile && <ServiceIntro3Mo/>}

      {/*/!* FAQ *!/*/}
      {isPc && <Faq/>}
      {isMobile && <FaqMo/>}

      {/*/!* 고객사 리스트 *!/*/}
      {isPc && <ServiceCustomer/>}

      {/* 온라인 상담 */}
      {isPc && <RequestForm/>}
      {isMobile && <RequestFormMo/>}

      {/* Footer */}
      {isPc && <Footer/>}
      {isMobile && <FooterMo/>}
    </>);
  }

  return (<div ref={appRef} style={{display: "flex", flexDirection: "column"}}>
      {/* GNB */}
      {isPc && <ServiceGNB appRef={appRef} childrenCB={contentChildren}/>}
      {isMobile && <ServiceGNBMo appRef={appRef} childrenCB={contentChildren}/>}
    </div>);
}

export default App;
