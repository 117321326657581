import React from "react";
import {Card, Grid, GridColumn, GridRow, Header, Image, Menu, Modal, ModalContent} from "semantic-ui-react";
import "./MenuInfo.css"
import MenuGallery from "./MenuGallery";
import MenuGalleryMo from "./MenuGalleryMo";
import {Tag} from "./Common";

export const MenuInfo = function ({content}) {
  const [open, setOpen] = React.useState(false);

  const modalCloseIconStyle = {
    display: "block",
    marginLeft: "auto",
    width: "12px",
    height: "12px",
    cursor: "pointer"
  }

  const modalTitleStyle = {
    fontFamily: "Noto Sans KR",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.4px",
    color: "#000000"
  }

  const onOpen = () => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[클릭] 메뉴');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }
    setOpen(true);
  }

  const renderTrigger = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} id="menu4"
           className="menu-item">
        <Tag title={'조식'}/>
        {content}
      </div>
    );
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => onOpen()}
      open={open}
      trigger={renderTrigger()}
    >
      <Modal.Content>
        <Header style={{marginLeft: "11px", height: "48px"}}>
          <div style={{textAlign: "left", float: "left"}}>
            <span style={modalTitleStyle}>메뉴 안내</span>
          </div>
          <div>
            <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
                   style={modalCloseIconStyle}/>
          </div>
        </Header>
        <div className="menuInfoModal-content">
          <label className="menuInfo-info">새로운 메뉴를 구성하여 다양한 밀박스를 제공해 드립니다. 선택에 대한 고민 없이 밀박스25로 다양한 상품을 만나보세요!</label>
        </div>
        <div className="MenuInfoCards">
          <div className="menuInfo-card">
            <img src="./images/intro2_01.png" alt="" style={{width: "280px", height: "280px"}}/>
          </div>
          <div className="menuInfo-card">
            <img src="./images/intro2_02.png" alt="" style={{width: "280px", height: "280px"}}/>
          </div>
          <div className="menuInfo-card">
            <img src="./images/intro2_03.png" alt="" style={{width: "280px", height: "300px"}}/>
          </div>
          <div className="menuInfo-card">
            <img src="./images/intro2_04.png" alt="" style={{width: "280px", height: "300px"}}/>
          </div>
          <div className="menuInfo-card">
            <img src="./images/intro2_05.png" alt="" style={{width: "280px", height: "300px"}}/>
          </div>
          <div className="menuInfo-card">
            <img src="./images/intro2_06.png" alt="" style={{width: "280px", height: "300px"}}/>
          </div>
        </div>
        <div style={{textAlign: "center"}}>
          <MenuGallery isPc={true}/>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export const MenuInfoMo = function ({content, callbackFn}) {
  const [open, setOpen] = React.useState(false);

  const onOpen = () => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[클릭] 메뉴');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }
    setOpen(true);
  }

  const modalStyle = {
    width: "100%",//windowSize.width + "px",
    height: "100vh",//windowSize.height + "px",
    margin: "0px",
    padding: "0px",
    top: "0px",
    left: "0px",
  }

  const modalCloseIconStyle = {
    display: "block",
    marginLeft: "auto",
    width: "12px",
    height: "12px",
    cursor: "pointer"
  }

  const modalTitleStyle = {
    fontFamily: "Noto Sans KR",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.4px",
    color: "#1E2530"
  }
  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
    padding: "18px 4px",
    margin: "0px",
    height: "56px",
    fontFamily: "Noto Sans KR",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    /* identical to box height, or 125% */
    letterSpacing: "-0.4px",
    color: "rgba(0, 0, 0, 0.88)",
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {
        onOpen();
        callbackFn();
      }}
      open={open}
      style={modalStyle}
      trigger={<Menu.Item style={menuItemStyle} as='a'>{content} <Tag style={{marginLeft: 5}} title="조식"/></Menu.Item>}
    >
      <Modal.Content>
        <Header style={{marginBottom: "22px"}}>
          <div style={{textAlign: "left", float: "left"}}>
            <span style={modalTitleStyle}>메뉴 안내</span>
          </div>
          <div style={{padding: "6px"}}>
            <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
                   style={modalCloseIconStyle}/>
          </div>
        </Header>
        <div className="MenuInfoCards-Mo-contentDiv">
          <div className="MenuInfoCards-Mo-content">
            <div>새로운 메뉴를 구성하여 다양한 밀박스를 제공해 드립니다.</div>
            <div>선택에 대한 고민 없이 밀박스25로 다양한 상품을 만나보세요!</div>
          </div>
        </div>
        <div className="MenuInfoCards-Mo">
          <div style={{marginTop: "12px", display: "flex", float: "left"}}>
            <img src="./images/intro2_01_mo.png" alt="" style={{width: "159px", height: "149px", marginRight: "10px"}}/>
            <img src="./images/intro2_02_mo.png" alt="" style={{width: "159px", height: "149px"}}/>
          </div>
          <div style={{marginTop: "10px", display: "flex", float: "left"}}>
            <img src="./images/intro2_03_mo.png" alt="" style={{width: "159px", height: "149px", marginRight: "10px"}}/>
            <img src="./images/intro2_04_mo.png" alt="" style={{width: "159px", height: "149px"}}/>
          </div>
          <div style={{marginTop: "10px", marginBottom: "16px", display: "flex", float: "left"}}>
            <img src="./images/intro2_05_mo.png" alt="" style={{width: "159px", height: "149px", marginRight: "10px"}}/>
            <img src="./images/intro2_06_mo.png" alt="" style={{width: "159px", height: "149px"}}/>
          </div>
        </div>
        <div style={{textAlign: "center"}}>
          <MenuGalleryMo isMobile={true}/>
        </div>
      </Modal.Content>
    </Modal>
  );
}
