import React from "react";
import {Card,Header, Image, Modal} from "semantic-ui-react";

const PersonalInformModal = function ({content}) {
    const [open, setOpen] = React.useState(false);

    const modalCloseIconStyle = {
        display: "block",
        marginLeft: "auto",
        width: "12px",
        height: "12px"
    }
    const modalStyle = {
        width:"100%",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#FA4614",
        textDecorationLine: "underline",
        cursor: "pointer",

        marginLeft: "4px"
    }
    const modalTitleStyle = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "#000000"
    }
    const firstDivTitleStyle = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }
    const firstDivContentStyle = {
        marginTop: "24px",
        textAlign: "left",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "28px",
        color: "#000000",
        letterSpacing: "-0.4px"
    }
    const secondDivContentStyle = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }
    const modalTableTrStyle = {
        textAlign: "center"
    }
    const modalTableThStyle1 = {
        border: "1px solid #EAECF2",
        borderRight: 'none',
        borderBottom: 'none',
        borderTopLeftRadius: '8px',
        background: "#F5F5F5",
        padding: "12px",
        width: "4%",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle2 = {
        border: "1px solid #EAECF2",
        borderRight: 'none',
        borderBottom: 'none',
        background: "#F5F5F5",
        padding: "12px",
        width: "9%",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle3 = {
        border: "1px solid #EAECF2",
        borderRight: 'none',
        borderBottom: 'none',
        background: "#F5F5F5",
        padding: "12px",
        width: "32%",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle4 = {
        border: "1px solid #EAECF2",
        borderBottom: 'none',
        borderTopRightRadius: '8px',
        background: "#F5F5F5",
        padding: "12px",
        width: "13%",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle5 = {
        border: "1px solid #EAECF2",
        borderRadius: "8px 0px 0px 0px",
        background: "#F5F5F5",
        padding: "12px",
        width: "10%",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle6 = {
        border: "1px solid #EAECF2",
        borderRadius: "8px 0px 0px 0px",
        background: "#F5F5F5",
        padding: "12px",
        width: "13%",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableTdStyle1 = {
        border: "1px solid #EAECF2",
        borderRight: 'none',
        boxSizing: "border-box",
        borderRadius: "0px",
        padding: "11px",
        alignItems: "center",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "24px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "center"
    }
    const modalTableTdStyle = {
        border: "1px solid #EAECF2",
        boxSizing: "border-box",
        borderRadius: "0px",
        padding: "11px",
        alignItems: "center",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "24px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "left"
    }
    const modalTableTdBoldStyle = {
        border: "1px solid #EAECF2",
        boxSizing: "border-box",
        borderRadius: "0px",
        padding: "11px",
        alignItems: "center",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontSize: "13px",
        lineHeight: "24px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "left"
    }
    const labelStyle1 = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }
    const labelStyle2 = {
        marginTop: "8px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            style={{width: "656px" , height:"auto"}}
            trigger={<a href="#none" style={modalStyle}>{content}</a>}
        >
            <Modal.Content>
                <Header>
                    <div style={{textAlign: "left", float: "left"}}>
                        <span style={modalTitleStyle}>개인정보 수집·이용 동의서</span>
                    </div>
                    <div>
                        <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
                               style={modalCloseIconStyle}/>
                    </div>
                </Header>
            </Modal.Content>
            <Modal.Content scrolling style={{width: "100%"}}>
                <Modal.Description>
                    <Card centered style={{width: "100%"}}>
                        <div style={{margin: "10px", marginBottom: "15px"}}>
                            <div style={{ textAlign: "center"}}>
                                <span style={firstDivTitleStyle}>개인정보 수집·이용 동의서</span>
                                <p style={firstDivContentStyle}>
                                    본인은 (주)지에스리테일(이하 ‘회사’라 함)의 기업복지형 플랫폼 서비스(‘밀박스25’)에 대한 온라인 상담을 신청함에 있어 개인정보보호법에 따라 개인정보를 수집·이용하는 것에 동의합니다.
                                </p>
                            </div>
                            <div style={{marginTop: "32px"}}>
                                <p style={secondDivContentStyle}>
                                    제1조 수집하는 개인정보 항목 및 이용 목적 등
                                </p>
                                <table style={{
                                    borderCollapse: "separate",
                                    borderSpacing: '0',
                                    width: "100%"
                                }}>
                                    <tbody>
                                    <tr style={modalTableTrStyle}>
                                        <th style={modalTableThStyle1}>구분</th>
                                        <th style={modalTableThStyle2}>수집항목</th>
                                        <th style={modalTableThStyle3}>목적</th>
                                        <th style={modalTableThStyle4}>보유 및 이용기간</th>
                                    </tr>
                                    <tr>
                                        <td style={{...modalTableTdStyle1, borderBottom:'none'}}>필수 정보</td>
                                        <td style={{...modalTableTdStyle, borderRight: 'none', borderBottom:'none', verticalAlign: 'baseline'}}>
                                            담당자명, 휴대전화번호, 이메일 주소, 추천인정보
                                        </td>
                                        <td style={{...modalTableTdStyle, borderRight:'none', borderBottom: 'none', verticalAlign:'baseline'}}>
                                            <div>- 온라인 상담 신청</div>
                                            <div>- 서비스 소개, 서비스 문의 등 고객 문의에 대한 전화상담</div>
                                            <div>- 배송을 위한 커뮤니케이션(전화, 문자,  이메일 및 카카오톡 등을 활용)</div>
                                            <div>- 공지, 안내를 위한 의사소통 전달</div>
                                            <div>- 새로운 서비스 및 이벤트 광고성 정보 제공</div>
                                            <div>- 추천인 포상/혜택 제공</div>
                                        </td>
                                        <td style={{...modalTableTdBoldStyle, borderBottom: 'none', verticalAlign:'baseline'}}>
                                            ① 온라인 상담 접수 후  3개월
                                            <div>②서비스이용종료일로부터 3개월</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{...modalTableTdStyle1, borderBottomLeftRadius: '8px'}}>기타</td>
                                        <td style={{...modalTableTdStyle, borderBottomRightRadius:'8px'}} colSpan={3}>
                                            <div>※ 관계 법령에서 정한 기간동안 보관하는 회원 정보는 아래와 같습니다.</div>
                                            <div>가. 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)</div>
                                            <div>나. 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)</div>
                                            <div>다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)</div>
                                            <div>라. 표시/광고에 관한 기록 : 6개월</div>
                                            <div>마. 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류 : 5년</div>
                                            <div>바. 전자금융에 관한 기록 : 5년</div>
                                            <div>사. 로그인 기록 : 3개월</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div style={{marginTop: "8px"}}>
                                    <label style={labelStyle1}>
                                        ※ 개인정보 수집 및 이용을 거부할 수 있는 권리가 있으나, 거부 시 서비스 이용이 불가합니다.
                                    </label>
                                </div>
                            </div>
                            {/*<div style={{marginTop: "32px"}}>*/}
                            {/*    <p style={secondDivContentStyle}>*/}
                            {/*        제2조 개인정보처리 위탁 동의*/}
                            {/*    </p>*/}
                            {/*    <div style={labelStyle2}>*/}
                            {/*        <label>*/}
                            {/*            ① 본인은 회사의 기업복지형 플랫폼 서비스 향상을 위하여 아래와 같이 외부 전문업체 등에  본인의 개인정보*/}
                            {/*            를 위탁함에 동의합니다.*/}
                            {/*        </label>*/}
                            {/*        <div>② 위탁 기간의 경우 위탁 계약 종료시 까지 지속됩니다.</div>*/}
                            {/*    </div>*/}
                            {/*    <table style={{*/}
                            {/*        border: "1px solid #F5F5F5",*/}
                            {/*        borderCollapse: "collapse",*/}
                            {/*        width: "100%",*/}
                            {/*        marginTop: "16px"*/}
                            {/*    }}>*/}
                            {/*        <tbody>*/}
                            {/*        <tr style={modalTableTrStyle}>*/}
                            {/*            <th style={modalTableThStyle5}>수탁업체</th>*/}
                            {/*            <th style={modalTableThStyle6}>위탁업무 내용</th>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td style={modalTableTdStyle1}>GS25 직영매장 및 가맹점</td>*/}
                            {/*            <td style={modalTableTdStyle1}>*/}
                            {/*                <div>물품 배달, 커뮤니케이션 등의 업무 처리 위함</div>*/}
                            {/*                <div>(제 1조의 수집항목 성명, 연락처만 위탁)</div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td style={modalTableTdStyle1}>정보 전산 처리 및 유지관리 </td>*/}
                            {/*            <td style={modalTableTdStyle1}>*/}
                            {/*                <div>AWS </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</div>*/}
                            {/*<div style={{marginTop: "32px"}}>*/}
                            {/*    <p style={secondDivContentStyle}>*/}
                            {/*        제3조*/}
                            {/*    </p>*/}
                            {/*    <div style={labelStyle2}>*/}
                            {/*        <label>*/}
                            {/*            본인은 상기 조건에 동의하지 않고 본인의 개인정보 수집 및 이용을 거부할 수 있는 권리를 가짐을 확인하며 이를 거부할 경우 서비스 이용이 불가함을 인지합니다.*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div style={{marginTop: "32px"}}>*/}
                            {/*    <p style={secondDivContentStyle}>*/}
                            {/*        제4조*/}
                            {/*    </p>*/}
                            {/*    <div style={labelStyle2}>*/}
                            {/*        <label>*/}
                            {/*            그밖의 사항은 '개인정보처리방침'을 따릅니다.*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </Card>

                </Modal.Description>
            </Modal.Content>

        </Modal>
    );
}

export default PersonalInformModal;
