import React from "react";
import "./ServiceIntro1.css"

export const ServiceIntro1 = () => {
    return (
        <div className="intro1">
            <div id="breakfast" className="title">
                <label>맞춤형 식단 제안</label>
            </div>
            <div className="content">
                <label>원하는 식사 형태와 예산에 맞춰 식단을 제안 드립니다.</label>
                <label>식사 대용 샐러드, 샌드위치, 김밥부터 식후 디저트와 음료까지 1인 구성으로 제공합니다.</label>
            </div>
            <div className="cards">
                <div className="card">
                    <img src="./images/intro1_01.png" style={{width: "280px", height: "240px"}} alt=""/>
                    <label className="card-name">고객사 취향 분석</label>
                </div>
                <div className="card">
                    <img src="./images/intro1_02.png" style={{width: "280px", height: "240px"}} alt=""/>
                    <label className="card-name">맞춤형 식단 제안</label>
                </div>
                <div className="card">
                    <img src="./images/intro1_03.png" style={{width: "280px", height: "240px"}} alt=""/>
                    <label className="card-name">개별 포장하여 무료배송</label>
                </div>
            </div>
        </div>
    );
};

export const ServiceIntro1Mo = () => {
    return (
        <div className="intro1-mo">
            <div id="breakfast" className="title">
                <label>맞춤형 식단 제안</label>
            </div>
            <div className="content">
                <label>원하는 식사 형태와 예산에 맞춰 식단을 제안 드립니다.</label>
                <label>식사 대용 샐러드, 샌드위치, 김밥부터</label>
                <label>식후 디저트와 음료까지 1인 구성으로 제공합니다.</label>
            </div>
            <div className="cards">
                <div className="card">
                    <img src="./images/intro1_01.png" style={{width: "280px", height: "240px"}} alt=""/>
                    <label className="card-name">고객사 취향 분석</label>
                </div>
                <div className="card">
                    <img src="./images/intro1_02.png" style={{width: "280px", height: "240px"}} alt=""/>
                    <label className="card-name">맞춤형 식단 제안</label>
                </div>
                <div className="card">
                    <img src="./images/intro1_03.png" style={{width: "280px", height: "240px"}} alt=""/>
                    <label className="card-name">개별 포장하여 무료배송</label>
                </div>
            </div>
        </div>
    );
};