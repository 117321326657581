import React from "react";
import {Sticky} from "semantic-ui-react";
import {getWindowInnerHeight, getWindowInnerWidth} from "../utils/windowUtil";
import {getWindowScrollY} from "../utils/windowUtil";

export const GoToTop = ({appRef}) => {
    const [showBtn, setShowBtn] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            let scrollY = getWindowScrollY();
            let newShowBtn = (scrollY < 100 ? false : true);
            if (showBtn !== newShowBtn) {
                setShowBtn(newShowBtn);
            }
        }

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [showBtn]);

    const gotoStyle = {
        position: "absolute",
        width: "60px",
        height: "60px",
        left: (getWindowInnerWidth() * 0.89) + "px" ,
        top: (getWindowInnerHeight() - 104) + "px",
        cursor: "pointer",
    }
    if (!showBtn) {
        gotoStyle.display = "none";
    }

    function goToTop() {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <Sticky id="sticky" context={appRef} offset={0.8}>
            <img src='./images/icon/ico_goto_top.svg' style={gotoStyle} onClick={goToTop} alt=""/>
        </Sticky>
    );
}

export const GoToTopMo = ({appRef, menuVisible}) => {
    const [showBtn, setShowBtn] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            let scrollY = getWindowScrollY();
            let newShowBtn = (scrollY < 60 ? false : true);
            if (showBtn !== newShowBtn) {
                setShowBtn(newShowBtn);
            }
        }

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [showBtn]);

    const gotoStyle = {
        position: "absolute",
        width: "60px",
        height: "60px",
        //padding: "6px",
        left: (getWindowInnerWidth() * 0.81) + "px" ,
        top: (getWindowInnerHeight() - 78) + "px",
        cursor: "pointer",
    }
    if (!showBtn) {
        gotoStyle.display = "none";
    }

    function goToTop() {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        (menuVisible ? <></> : (
        <Sticky id="sticky" context={appRef} offset={0.4}>
            <img src='./images/icon/ico_goto_top.svg' style={gotoStyle} onClick={goToTop} alt=""/>
        </Sticky>))
    );
}
