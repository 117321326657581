import React, {useState, useEffect, useRef} from "react";
import {Modal, Button, Image} from "semantic-ui-react";
import {getWindowInnerHeight, getWindowInnerWidth} from "../utils/windowUtil";

const SearchAddr = function ({content, retFunc, isPc}) {
    const buttonStyle = {
        width: "152px",
        height: "48px",
        marginLeft: "8px",
        marginBottom: "24px",
        borderRadius: "8px",

        fontFamily: "Noto Sans KR",
        fontSize: "16px",
        fontWeight: "bold"
    }

    const [open, setOpen] = useState(false);
    const [postSelected, setPostSelected] = useState(false);
    const [selectedPost, setSelectedPost] = useState({});
    const [detailAddr, setDetailAddr] = useState("");
    const [windowSize, setWindowSize] = React.useState({
        width: getWindowInnerWidth(),
        height: getWindowInnerHeight(),
        //width: window.innerWidth,
        //height: window.innerHeight,
    });

    const postContainer = useRef();

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    useEffect(() => {
        if (postContainer.current) {
            new window.daum.Postcode({
                oncomplete: function (data) {
                    setPostSelected(true);
                    setSelectedPost(data);
                },
                width: "100%",
                height: "100%",
                maxSuggestItems: 5
            }).embed(postContainer.current);
        }
    }, [open, postSelected]);

    const modalStyle = {
        width: (isPc ? "656" : windowSize.width) + "px",
        height: (isPc ? "538" : windowSize.height) + "px",
        margin: "0px",
        padding: "0px",
    }
    if (!isPc) {
        modalStyle.top = "0px";
        modalStyle.left = "0px";
        modalStyle.height = windowSize.height + "px";
    }

    const headerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: (isPc ? "100%" : windowSize.width + "px"),
        height: "48px",
        border: "0px",
        padding: "0px",
        paddingTop: "12px",
        paddingLeft: isPc ? "24px" : "16px",
        paddingRight: isPc ? "18px" : "12px",
    }
    const modalCloseIconStyle = {
        padding: "6px",
        display: "float",
        width: "24px",
        height: "24px",
        cursor: "pointer",
    }
    const modalGoBackIconStyle = {
        padding: "6px",
        width: "24px",
        height: "24px",
        cursor: "pointer",
    }
    const contentStyle = {
        textAlign: "left",
        margin: "0px",
        padding: "10px",
        height: (isPc ? "470" : windowSize.height - 48) + "px",
    }
    const postStyle = {
        height: (isPc ? "470" : windowSize.height - 78) + "px",
    }

    const addrTextStyle = {
        width: "100%",
        marginTop: "14px",
        marginLeft: "26px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: (isPc ? "16px" : "14px"),
        lineHeight: "22px",
        letterSpacing: "-0.4px",
        color: "#000000",
        background: "#FFFFFF",
    }

    const textAndBtnStyle = {
        marginTop: (isPc ? "14px" : "16px"),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    }

    const detailAddrStyle = {
        width: (isPc ? "608px" : (windowSize.width - 42) + "px"),
        height: "48px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
        boxSizing: "border-box",
    }
    const goBackBtnStyle = {
        width: "200px",
        height: "48px",
        marginTop: "48px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.88)",
        boxSizing: "border-box",
        borderRadius: "8px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "20px",
        textAlign: "center",
        letterSpacing: "-0.4px",
        color: "#000000",
    }
    const saveBtnStyle = {
        width: (isPc ? "608px" : windowSize.width - 40),
        height: "48px",
        marginTop: (isPc ? "16px" : "32px"),
        marginLeft: (isPc ? "0px" : "4px"),
        background: "#FF5A00",
        borderRadius: "8px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "20px",
        textAlign: "center",
        letterSpacing: "-0.4px",
        color: "#FFFFFF",
    }

    const onSelectAddr = (e) => {
        retFunc(selectedPost.roadAddress + " " + selectedPost.buildingName + " " + detailAddr);
        setOpen(false);
        setPostSelected(false);
    }
    const onGoBackToSearch = (e) => {
        setPostSelected(false);
    }

    const onDetailAddrChanged = (e) => {
        setDetailAddr(e.target.value);
    }

    return (
        <Modal
            size="large"
            open={open}
            onClose={() => {
                setOpen(false);
                setPostSelected(false);
            }}
            onOpen={() => {
                setOpen(true);
                setPostSelected(false);
            }}
            style={modalStyle}
            trigger={<Button fluid content={content} secondary style={buttonStyle}/>}
        >
            <Modal.Header style={headerStyle}>
                {((!isPc && postSelected) &&
                    <Image src='./images/icon/icon_back.svg' onClick={() => setPostSelected(false)}
                           style={modalGoBackIconStyle}/>
                )}

                {(postSelected ? "상세주소 입력" : "주소 찾기")}
                <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
                       style={modalCloseIconStyle}/>
            </Modal.Header>
            <Modal.Content style={contentStyle}>
                {postSelected ? (
                    <>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={addrTextStyle}>
                                <label>
                                    {selectedPost.roadAddress} {selectedPost.buildingName}
                                </label>
                            </div>
                            <div style={textAndBtnStyle}>
                                <input placeholder='나머지 주소(동/호수, 번지, 층수)' style={detailAddrStyle} value={detailAddr}
                                       onChange={onDetailAddrChanged}/>
                            </div>
                            <Button style={saveBtnStyle} onClick={onSelectAddr}>저장하기</Button>
                            {(isPc && <Button style={goBackBtnStyle} onClick={onGoBackToSearch}>재검색</Button>)}
                        </div>
                    </>
                ) : (
                    <div ref={postContainer}
                         id="postCode"
                         style={postStyle}
                    />
                )}
            </Modal.Content>
        </Modal>
    );
}

export default SearchAddr;
