import React from "react";
import {Image, Modal} from "semantic-ui-react";
import axios from "axios";

export const PopupBanner = function ({}) {
  const [open, setOpen] = React.useState(false);
  const [banner, setBanner] = React.useState(null);//PC화면 배너

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/tb-mb-banner`).then((res) => {
      if (res.data.banner !== null) {
        setOpen(true);
        setBanner(res.data.banner.url);

      }
    });
  }, []);


  return (<Modal
    onClose={() => setOpen(false)}
    open={open}
    style={{
      borderRadius: "8px", width: '657px', height: '388px', overflow: "hidden"
    }}>
    <div>
      <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
             style={{
               display: "block", margin: "18px 24px 18px auto", width: "12px", height: "12px", cursor: "pointer"
             }}/>
    </div>
    <div style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
    }}>
      <img src={`${process.env.REACT_APP_API_BASE_URL}${banner}`}
           style={{
             width: "657px", height: "340px"
           }}
      />
    </div>
  </Modal>);
}

export const PopupBannerMo = function ({content, callbackFn}) {
  const [open, setOpen] = React.useState(false);
  const [moBanner, setMoBanner] = React.useState(null);//모바일 화면 배너

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/tb-mb-banner`).then((res) => {
      if (res.data.moBanner != null) {
        setOpen(true);
        setMoBanner(res.data.moBanner.url);
      }
    });
  }, []);

  return (<Modal
    centered={true}
    onClose={() => setOpen(false)}
    open={open}
    style={{
      width: "320px", height: "418px", borderRadius: "8px", overflow: "hidden"
    }}
  >
    <div style={{
      height: "48px", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
    }}>
      <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
             style={{
               display: "block", margin: "18px 24px 18px auto", width: "12px", height: "12px", cursor: "pointer"
             }}/>
    </div>
    <div style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
    }}>
      <img src={`${process.env.REACT_APP_API_BASE_URL}${moBanner}`} style={{width: "320px", height: "370px"}}/>
    </div>
  </Modal>);
}
