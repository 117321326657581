import React from "react";
import "./ServiceIntro.css";
import {Button} from "semantic-ui-react";
import $ from "jquery";

export const ServiceIntro = () => {
  const handleClick = (e) => {
    window.amplitude.track('[클릭] 밀박스25소개서다운로드');
  };

  const goToOnlineRequest = (e) => {
    let elem = document.getElementById("request");
    if (elem) {
      $('body,html').animate({scrollTop: elem.offsetTop - 92});
    }
    window.amplitude.track('[클릭] 온라인상담');
  }

  return (
    <div className="intro">
      <img src={'/images/intro_logo.png'} alt={'intro_logo_image'} className="logo-image"/>
      <div className="header">
        <label>단체 조식/간식 정기구독 서비스</label>
      </div>
      <div className="content">
        <label>GS25가 만들어 믿을 수 있는 밀박스25!</label>
        <label>가까운 GS25 매장에서 간편하게 정기배송/픽업을 이용해 보세요.</label>
      </div>
      <div className="btn-wrapper">
        <Button className="online-service-btn" color='orange' style={{background: "#F34E1A"}}
                data-sc-action='button.click'
                data-sc-value='온라인 상담'
                onClick={goToOnlineRequest}>온라인 상담</Button>
        <a href="./resource/20240328_MEALBOX25.pdf"
           className="down-btn"
           data-sc-action='button.click'
           data-sc-value='밀박스25 소개서'
           onClick={handleClick}
           download
        >
          밀박스25 소개서 다운로드
          <img src="./images/icon/down_icon.png" alt=""/>
        </a>
      </div>
      {/*<div className="hash-tags">*/}
      {/*    <button className="hash-btn">#기업조식</button>*/}
      {/*    <button className="hash-btn">#기업복지</button>*/}
      {/*    <button className="hash-btn">#샌드위치박스</button>*/}
      {/*    <button className="hash-btn">#간편식</button>*/}
      {/*    <button className="hash-btn">#간식복지</button>*/}
      {/*    <button className="hash-btn">#정기구독</button>*/}
      {/*    <button className="hash-btn">#정기배송</button>*/}
      {/*    <button className="hash-btn">#단체행사</button>*/}
      {/*    <button className="hash-btn">#1인패키징</button>*/}
      {/*    <button className="hash-btn">#월간식단</button>*/}
      {/*</div>*/}
    </div>
  );
}

export const ServiceIntroMo = () => {

  const goToOnlineRequest = (e) => {
    let elem = document.getElementById("request");
    if (elem) {
      $('body,html').animate({scrollTop: elem.offsetTop - 92});
    }
    window.amplitude.track('[클릭] 온라인상담');
  }

  return (
    <div className="intro-mo">
      <img src={'/images/intro_logo.png'} alt={'intro_logo_image'} className="logo-image"/>
      <div className="header-mo">
        <label>단체 조식/간식</label>
        <label>정기구독 서비스</label>
      </div>
      <div className="content-mo">
        <label>GS25가 만들어 믿을 수 있는 밀박스25!</label>
        <label>가까운 GS25 매장에서</label>
        <label>간편하게 정기배송/픽업을 이용해 보세요.</label>
      </div>
      <div className='btn-wrapper-mo'>
        <Button className="online-service-btn-mo" color='orange' style={{background: "#F34E1A"}}
                data-sc-action='button.click'
                data-sc-value='온라인 상담'
                onClick={goToOnlineRequest}>온라인 상담</Button>
        <a href="./resource/20240328_MEALBOX25.pdf"
           target="_blank"
           className="down-btn-mo"
           data-sc-action='button.click'
           data-sc-value='밀박스25 소개서'
        >
          밀박스25 소개서 다운로드
          <img src="./images/icon/down_icon.png" alt=""/>
        </a>
      </div>
      {/*<div className="hash-tags-mo">*/}
      {/*    <button className="hash-btn-mo">#기업조식</button>*/}
      {/*    <button className="hash-btn-mo">#기업복지</button>*/}
      {/*    <button className="hash-btn-mo">#샌드위치박스</button>*/}
      {/*</div>*/}
      {/*<div className="hash-tags-mo">*/}
      {/*    <button className="hash-btn-mo">#간편식</button>*/}
      {/*    <button className="hash-btn-mo">#간식복지</button>*/}
      {/*    <button className="hash-btn-mo">#정기구독</button>*/}
      {/*    <button className="hash-btn-mo">#정기배송</button>*/}
      {/*</div>*/}
      {/*<div className="hash-tags-mo">*/}
      {/*    <button className="hash-btn-mo">#단체행사</button>*/}
      {/*    <button className="hash-btn-mo">#1인패키징</button>*/}
      {/*    <button className="hash-btn-mo">#월간식단</button>*/}
      {/*</div>*/}
    </div>
  );
}